<template>
  <div v-if="loading">
    <b-skeleton width="25%"></b-skeleton>
    <b-skeleton type="input"></b-skeleton>
  </div>
  <div v-else>
    <eg-selectable-table
      v-if="bulk"
      :value="selectedIds"
      :items="assignments"
      :total="assignments.length"
      :limit="assignments.length"
      :fields="fields"
      @input="selectAssignments"
    >
      <template v-slot:selectAll="{ selectedAll, selectAll }">
        <b-checkbox
          :checked="selectedAll"
          @change="selectAll"
          variant="info"
          :indeterminate="
            selectedIds.length > 0 && selectedIds.length < assignments.length
          "
        >
          Select all assignments
        </b-checkbox>
      </template>
    </eg-selectable-table>
    <eg-form-group v-else label="Assignment" required>
      <eg-dropdown
        :text="
          getSelectedAssignment(selectedIds[0])?.title || 'Select assignment'
        "
      >
        <b-dropdown-text v-if="assignments.length === 0">
          The selected classroom has no assignments. Choose a different
          classroom to continue.
        </b-dropdown-text>
        <b-dropdown-item-button
          v-for="assignmentData in assignments"
          :key="assignmentData.id"
          class="dropdown-item-wrapper"
          @click="() => selectAssignments([assignmentData.id])"
        >
          {{ assignmentData.title }}
        </b-dropdown-item-button>
      </eg-dropdown>
    </eg-form-group>
  </div>
</template>

<script>
import importApi from "@/api/requests/import";
import EgFormGroup from "@/components/global/eg_form_group.vue";
import EgDropdown from "@/components/global/eg_dropdown.vue";
import errorHandler from "./errorHandler";
import EgSelectableTable from "@/components/global/eg_selectable_table";

export default {
  components: {
    EgFormGroup,
    EgDropdown,
    EgSelectableTable,
  },

  props: {
    value: {
      type: Array,
    },

    classIds: {
      type: Array,
      required: true,
    },

    bulk: {
      type: Boolean,
      default: false,
    },
  },

  inject: ["serviceAccount", "serviceName"],

  data() {
    return {
      loading: true,
      selectedIds: [],
      assignments: [],
      fields: [
        {
          key: "title",
          label: "Assignment",
          formatter: (value, key, item) => {
            return item.title;
          },
        },
        {
          key: "class",
          label: "Class",
          formatter: (value, key, item) => {
            return item.class.name;
          },
        },
      ],
    };
  },
  methods: {
    getSelectedAssignment(id) {
      return (
        this.assignments.find((assignment) => assignment.id === id) || null
      );
    },

    selectAssignments(selected) {
      this.selectedIds = selected;
      const selectedAssignments = this.assignments.filter((assignment) =>
        this.selectedIds.includes(assignment.id)
      );
      this.$emit("input", selectedAssignments);
      const assignmentDefaultRubricIds = selectedAssignments
        .filter((assignment) => assignment.defaultRubricId)
        .map((assignment) => assignment.defaultRubricId);
      assignmentDefaultRubricIds.length &&
        this.$emit("setAssignmentDefaultRubricId", assignmentDefaultRubricIds);
    },

    async init() {
      try {
        this.loading = true;
        const method = this.bulk ? "getBulkAssignments" : "getAssignments";
        const { data } = await importApi[this.serviceName][method](
          this.serviceAccount.id,
          this.classIds
        );
        this.assignments = data.assignments;

        const firstAssignmentId = this.assignments[0]?.id;
        let selectedAssignmentIds = firstAssignmentId
          ? [firstAssignmentId]
          : [];

        if (this.value) {
          const valueIds = this.value.map((assignment) => assignment.id);
          const assignmentIds = this.assignments
            .filter((assignment) => valueIds.includes(assignment.id))
            .map((assignment) => assignment.id);

          if (assignmentIds) {
            selectedAssignmentIds = assignmentIds;
          }
        }

        selectedAssignmentIds && this.selectAssignments(selectedAssignmentIds);
        this.loading = false;
        this.$emit("loaded");
      } catch (error) {
        this.loading = false;
        this.$emit("loaded");
        errorHandler({
          vm: this,
          error,
          message:
            "There was a problem fetching assignments for the classroom. Please try again",
        });
      }
    },
  },

  created() {
    this.init();
  },
};
</script>
