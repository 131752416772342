<template>
  <div class="rubric-filter">
    <div
      class="filter-container p-3 d-flex flex-align-center justify-content-between"
    >
      <div class="w-100">
        <b-form-input
          id="rubricSearch"
          v-model="searchQuery"
          size="sm"
          placeholder="Filter by rubric name"
          aria-label="Filter by rubric name"
          class="search-input w-100"
          @input="updateValue"
        ></b-form-input>
      </div>
      <div
        v-if="showAddNew && $listeners['click:create']"
        class="ml-3 flex-shrink-0"
      >
        <b-button
          size="sm"
          variant="outline-info"
          @click="$emit('click:create')"
        >
          Create new rubric
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "RubricFilter",

  emits: ["click:create", "input"],

  props: {
    value: String,
    showAddNew: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      searchQuery: "",
    };
  },

  methods: {
    updateValue() {
      this.$emit("input", this.searchQuery);
    },
  },
};
</script>

<style scoped>
.rubric-filter {
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
  background-color: var(--Background-Light, #f8f9fa);
}

.search-input {
  border: 1px solid var(--Border-Light, #dfdfdf);
  background-color: var(--Background-White, #fff);
  font-family: Inter, sans-serif;
  color: var(--Text-Muted, #6c757d);
}

.create-rubric-btn {
  border-color: var(--secondary, #f3e8fc);
  background-color: var(--Text-White, #fff);
  font-family: Roboto, sans-serif;
  color: rgba(113, 75, 212, 1);
}
</style>
