<template>
  <div>
    <b-button
      v-if="!value"
      variant="outline-info"
      size="sm"
      v-b-toggle.rubric-library-sidebar
    >
      Choose rubric from library
    </b-button>
    <div v-if="value" class="d-flex align-items-start">
      <div>
        <div class="rubric-name">{{ value?.name }}</div>
        <div class="rubric-created-by">
          Created by
          <b>
            {{ formatAuthorValue(value?.author, $store) || "Me" }}
          </b>
        </div>
      </div>
      <b-button
        v-if="value"
        class="ml-4"
        variant="outline-info"
        size="sm"
        v-b-toggle.rubric-library-sidebar
      >
        Choose a different rubric
      </b-button>
    </div>
    <rubric-library
      v-model="selectedRubric"
      @input="selectRubric"
      show-add-new
    />
  </div>
</template>

<script>
import RubricLibrary from "@/components/rubrics/rubric_library.vue";
import { formatAuthorValue } from "@/utils/format";

export default {
  components: {
    RubricLibrary,
  },

  emits: ["input"],

  props: ["value"],

  data() {
    return {
      selectedRubric: null,
    };
  },

  watch: {
    value: {
      handler(newValue) {
        if (newValue && newValue.id) {
          this.selectedRubric = newValue;
        }
      },
      immediate: true,
    },
  },

  methods: {
    formatAuthorValue,

    selectRubric(rubric) {
      this.$emit("input", rubric);
    },
  },
};
</script>

<style lang="scss">
.rubric-name {
  color: var(--Text-Body, #212529);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.rubric-created-by {
  color: var(--Text-Body, #212529);
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
}
</style>
