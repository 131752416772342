<script>
import SettingsSidebar from "@/components/rubrics/newRubric/settings_sidebar.vue";
import RubricForm from "@/components/rubrics/rubric_form.vue";
import SettingsPreviewGrid from "@/components/rubrics/newRubric/settings_preview_grid.vue";
import UploadContentArea from "@/components/dashboard/upload_content_area.vue";
import Settings from "@/components/rubrics/newRubric/settings.vue";
import { generateRubricByAiByUpload } from "@/api";
import UploadSidebar from "@/components/rubrics/newRubric/upload_sidebar.vue";
import AiLoadingCard from "@/components/rubrics/newRubric/subComponents/AiLoadingCard.vue";

export default {
  name: "RubricAiUpload",
  components: {
    UploadSidebar,
    Settings,
    SettingsPreviewGrid,
    RubricForm,
    SettingsSidebar,
    UploadContentArea,
    AiLoadingCard,
  },
  emits: ["createRubric", "close"],
  inject: [
    "updateRubricSettings",
    "clearRubricSettings",
    "newRubricData",
    "newRubricSettings",
  ],
  data() {
    return {
      step: 0,
      settings: {
        grade: true,
        intensity: true,
        language: true,
      },
      rubric: null,
      filesToUpload: [],
      file: null,
      aiLoading: false,
      uploadInProgress: false,
    };
  },
  methods: {
    async callAi() {
      this.aiLoading = true;
      try {
        const { data } = await generateRubricByAiByUpload({
          gradeLevel:
            this.newRubricData.grades[this.newRubricSettings.selectedGradeIdx]
              .value,
          intensity:
            this.newRubricData.intensities[
              this.newRubricSettings.selectedIntensityIdx
            ].value,
          LanguageId:
            this.newRubricData.languages.value[
              this.newRubricSettings.selectedLanguageIdx
            ].id,
          fileUrls: [this.file.fileUrl],
        });
        this.rubric = data;
      } catch (e) {
        console.error(e);
        this.$showToastError(
          "There was a problem creating the rubric. Please try again, or contact support",
          { title: "Rubric Creation failed" }
        );
        this.step = 1;
      } finally {
        this.aiLoading = false;
      }
    },
    onUpload(e) {
      this.file = e[0];
      this.step = 2;
      this.callAi();
    },
    onFileAdded(e) {
      this.filesToUpload = e;
    },
    onFileRemoved(e) {
      this.filesToUpload = e;
    },
    async upload() {
      this.uploadInProgress = true;
      await this.$refs.uploadArea.upload();
      this.uploadInProgress = false;
    },
    onSaveSettings() {
      if (this.step === 2) {
        this.callAi();
      }
    },
  },
  beforeMount() {
    this.clearRubricSettings();
  },
};
</script>

<template>
  <b-row>
    <SettingsSidebar v-bind="settings" @onSave="onSaveSettings" />
    <UploadSidebar
      @input-files="onUpload($event)"
      @file-added="onFileAdded($event)"
      @file-removed="onFileRemoved($event)"
      :can-upload="filesToUpload.length > 0"
    />
    <template v-if="step === 0">
      <b-col cols="12">
        <h3>Details</h3>
      </b-col>
      <b-col cols="12">
        <Settings v-bind="settings" />
      </b-col>
      <b-col cols="12" class="d-flex justify-content-end">
        <b-button variant="outline-info" @click="$emit('close')">
          Cancel
        </b-button>
        <b-button
          class="ml-3 avoid-support-overlap"
          variant="info"
          @click="step = 1"
        >
          Continue
        </b-button>
      </b-col>
    </template>

    <template v-if="step === 1">
      <b-col cols="12" class="mb-3">
        <h3>Details</h3>
        <SettingsPreviewGrid v-bind="settings" />
      </b-col>
      <b-col cols="12">
        <h3>Upload or import</h3>
        <UploadContentArea
          class="upload-content-area"
          ref="uploadArea"
          label="Upload rubric"
          required
          :max-number-of-files="1"
          :availableVariants="['files']"
          @input-files="onUpload($event)"
          @file-added="onFileAdded($event)"
          @file-removed="onFileRemoved($event)"
        />
      </b-col>

      <b-col cols="12" class="d-flex justify-content-end">
        <b-button variant="outline-info" @click="$emit('close')">
          Cancel
        </b-button>
        <b-button
          :disabled="filesToUpload.length === 0 || uploadInProgress"
          class="ml-3 avoid-support-overlap"
          variant="info"
          @click="upload"
        >
          Continue
        </b-button>
      </b-col>
    </template>
    <template v-else-if="step === 2">
      <b-col cols="12" class="mb-3">
        <h3>Details</h3>
        <SettingsPreviewGrid v-bind="settings" />
      </b-col>
      <b-col cols="12" class="mb-3">
        <h3>Upload or import</h3>
        <b>Uploaded files</b>
        <div>
          <div class="file">
            <b-icon icon="file-earmark-text" />
            <span>{{ file.name }}</span>
            <b-button
              variant="outline-info"
              size="sm"
              v-b-toggle.new-rubric-upload-sidebar
            >
              Edit
            </b-button>
          </div>
        </div>
      </b-col>
      <b-col cols="12">
        <b-skeleton-wrapper class="flex-grow-1" :loading="aiLoading">
          <template #loading>
            <AiLoadingCard />
          </template>

          <rubric-form
            :rubric="rubric"
            editing
            @rubricSubmitted="$emit('createRubric', $event)"
            @close="$emit('close')"
          />
        </b-skeleton-wrapper>
      </b-col>
    </template>
  </b-row>
</template>

<style scoped lang="scss">
.upload-content-area::v-deep {
  .uppy-Dashboard {
    height: 325px;
  }
}
.upload-content-area {
  max-width: 800px;
}
.file {
  border-bottom: solid #dfdfdf 1px;
  display: inline-flex;
  align-items: center;
  padding: 0.4rem 1rem;
  span {
    margin-right: 2rem;
    margin-left: 0.5rem;
  }
}
</style>
