<script>
import Breadcrumbs from "@/components/global/breadcrumbs.vue";
import ClassForm from "@/components/classes/class_form.vue";

export default {
  name: "NewClassView",

  emits: ["input", "close"],

  components: {
    Breadcrumbs,
    ClassForm,
  },

  props: {
    isRedirectAfterCreated: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    breadcrumbs() {
      return [
        {
          text: "Classes",
          href: "/classes",
        },
        {
          text: "New Class",
          active: true,
        },
      ];
    },
  },

  methods: {
    handleClose() {
      if (this.$listeners["close"]) {
        this.$emit("close");
      } else {
        this.$router.push("/classes");
      }
    },
  },
};
</script>

<template>
  <b-container>
    <section>
      <breadcrumbs :items="breadcrumbs" />
      <b-row>
        <b-col lg="6">
          <h1>New class</h1>
        </b-col>
      </b-row>
      <class-form
        :is-redirect-after-created="isRedirectAfterCreated"
        @close="handleClose"
        @input="$emit('input', $event)"
      />
    </section>
  </b-container>
</template>
