<template>
  <div class="sort-dropdown-wrapper d-flex align-items">
    <b-dropdown
      class="sort-dropdown"
      size="sm"
      :text="`Sort by: ${sortBy.label}`"
    >
      <b-dd-item
        v-for="option in sortOptions"
        :key="option.value"
        @click="onClickSortBy(option)"
      >
        {{ option.label }}
      </b-dd-item>
    </b-dropdown>
    <div class="sort-desc" @click="onClickSortDesc">
      <b-icon :icon="`sort-${sortDesc ? 'down' : 'up'}-alt`"></b-icon>
    </div>
  </div>
</template>

<script>
const allSorts = {
  studentName: { value: "studentLastName", label: "Student last name" },
  createdAt: { value: "createdAt", label: "Graded date" },
  name: { value: "name", label: "Essay name" },
  grade: { value: "grade", label: "Grade" },
  class: { value: "ClassId", label: "Class" },
  assignment: { value: "assignment", label: "Assignment" },
  rubric: { value: "rubric", label: "Rubric" },
};

export default {
  name: "SortDropdown",

  props: {
    availableSort: {
      type: Array,
      default: () => ["createdAt", "name", "class", "assignment", "rubric"],
    },
  },

  data() {
    return {
      sortBy: {},
      sortDesc: false,
      sortOptions: [],
    };
  },

  created() {
    this.sortOptions = this.availableSort.map((filter) => {
      return allSorts[filter];
    });
    this.sortBy = this.sortOptions[0];
  },

  methods: {
    onClickSortDesc() {
      this.sortDesc = !this.sortDesc;
      this.emitSort();
    },

    onClickSortBy(option) {
      this.sortBy = option;
      this.emitSort();
    },

    emitSort() {
      this.$emit("sort", {
        sortBy: this.sortBy.value,
        sortDesc: this.sortDesc,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.sort-dropdown-wrapper {
  /* --sort-color: var(--gray);
  --sort-border-color: var(--secondary); */
  --sort-color: var(--brand, #714bd4);
  --sort-border-color: var(--brand, #714bd4);

  height: 31px;
  flex-shrink: 0;
  border-radius: 4px;
  border: 1px solid var(--sort-border-color);
  color: var(--sort-color);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */

  .sort-dropdown::v-deep {
    .btn {
      padding: 0;
      border: none;
      outline: none;
      background: transparent;
      color: var(--sort-color);
      padding-right: 8px;
      padding-left: 8px;
      box-shadow: unset;
    }
  }

  .sort-desc {
    line-height: 1;
    padding: 8px;
    border-left: 1px solid var(--sort-border-color);
    cursor: pointer;
  }
}
</style>
