import { app } from "@/main";
import store from "@/store";
import { errorTable } from "@/constants";
import { unpause } from "./requests/subscription";
const ignoredUrls = [
  "/login",
  "/register",
  "/forgot-password",
  "/assignments/files",
]; // show error directly on page (Alert)

const trackAndRedirectToUpgrade = (reason) => {
  app.$trackEvent("upgrade_clicked", { reason });
  app.$router.push("/account");
};

export default function errorHandler(error) {
  if (ignoredUrls.includes(error.response.config.url)) {
    return Promise.reject(error);
  }

  const status = error.response?.status;

  if (status === 401) {
    store.dispatch("logout");
    location.pathname = "/login?ref=expired";
  } else {
    const id = `error-toast-${Date.now()}`;
    const code = error.response?.data?.code;
    let message =
      error?.response?.data?.errors?.[0]?.message ||
      error?.response?.data?.error ||
      "There was a problem processing your request. Please try again or contact support.";
    let title = "Error";

    if (code) {
      const h = app.$createElement;
      switch (code) {
        case "pausedLimitsExceeded": {
          message = [
            h("p", { class: ["text-center", "mb-0"] }, [
              errorTable["pausedLimitsExceeded"],
              h("br"),
              h(
                "b-button",
                {
                  class: ["mt-2"],
                  props: { variant: "info" },
                  on: {
                    click: async () => {
                      await unpause(app);
                      app.$bvToast.hide(id);
                    },
                  },
                },
                "Resume"
              ),
            ]),
          ];
          break;
        }
        case "planWordLimitsExceeded": {
          message = [
            h("p", { class: ["text-center", "mb-0"] }, [
              errorTable["planWordLimitsExceeded"],
              h("br"),
              h(
                "b-button",
                {
                  class: ["mt-2"],
                  props: { variant: "info" },
                  on: {
                    click: () => trackAndRedirectToUpgrade(code),
                  },
                },
                "Click here to upgrade"
              ),
            ]),
          ];
          break;
        }
        case "planAdvancedRubricEssaysLimitExceeded": {
          message = [
            h("p", { class: ["text-center", "mb-0"] }, [
              errorTable["planAdvancedRubricEssaysLimitExceeded"],
              h("br"),
              h(
                "b-button",
                {
                  class: ["mt-2"],
                  props: { variant: "info" },
                  on: {
                    click: () => {
                      trackAndRedirectToUpgrade(code);
                      app.$bvToast.hide(id);
                    },
                  },
                },
                "Click here to upgrade"
              ),
            ]),
          ];
          break;
        }
        case "planEssayLimitsExceeded": {
          message = [
            h("p", { class: ["text-center", "mb-0"] }, [
              errorTable["planEssayLimitsExceeded"],
              h("br"),
              h(
                "b-button",
                {
                  class: ["mt-2"],
                  props: { variant: "info" },
                  on: {
                    click: () => trackAndRedirectToUpgrade(code),
                  },
                },
                "Click here to upgrade"
              ),
            ]),
          ];
          break;
        }
        case "missedPermissions":
          app.$store.dispatch("setUser", {
            ...app.$store.state.user,
            isGCAvailable: false,
          });

          title = "Google Permissions Needed";
          message = errorTable["missedPermissions"];
          break;
        case "badGoogleResponse":
          message = errorTable["badGoogleResponse"];
          break;

        default:
          message = error.response?.data?.message || message;
          break;
      }
    }
    app.$bvToast.toast(message, {
      id,
      title,
      variant: "danger",
    });
  }

  return Promise.reject(error);
}
