<template>
  <b-skeleton
    v-if="classesLoading"
    type="input"
    animation="wave"
    width="100%"
  />
  <div v-else>
    <eg-search-select
      v-if="withSearch"
      :items="allClasses"
      :value="selectedClass"
      titleKey="name"
      entity="class"
      allowDeselect
      @input="selectClass"
      @click:create="onClickCreate"
    />

    <eg-dropdown
      v-else
      :size="size"
      :text="selectedClass ? selectedClass.name : 'Select class'"
    >
      <b-dropdown-text v-if="allClasses.length === 0">
        No classes have been created yet.
      </b-dropdown-text>

      <b-dropdown-item-button
        v-else
        @click="() => selectClass({ id: null, name: 'No class' })"
      >
        No class
      </b-dropdown-item-button>
      <b-dropdown-item-button
        v-for="classData in allClasses"
        :key="classData.id"
        class="dropdown-item-wrapper"
        @click="() => selectClass(classData)"
      >
        {{ classData.name }}
      </b-dropdown-item-button>
    </eg-dropdown>
  </div>
</template>

<script>
import { getClasses } from "@/api";
import EgDropdown from "@/components/global/eg_dropdown";
import EgSearchSelect from "@/components/global/eg_search_select.vue";

export default {
  components: {
    EgDropdown,
    EgSearchSelect,
  },

  props: {
    value: {
      type: Object,
    },

    valueIsId: {
      type: Boolean,
      default: false,
    },

    classes: {
      type: Array,
    },

    size: {
      type: String,
      default: "md",
    },

    withSearch: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      classesLoading: false,
      allClasses: [],
    };
  },

  computed: {
    selectedClass() {
      return this.valueIsId
        ? this.allClasses.find((c) => c.id === this.value)
        : this.value;
    },
  },

  methods: {
    onClickCreate() {
      this.$openForm("class", {
        props: { isRedirectAfterCreated: false },
        callback: async (value) => {
          await this.fetchClasses();
          this.selectClass(value);
        },
      });
    },

    selectClass(classData) {
      this.$emit("input", this.valueIsId ? classData.id : classData);
    },

    async fetchClasses() {
      this.classesLoading = true;
      try {
        const { data } = await getClasses({ limit: 1000 });

        this.allClasses = data.classes;
        // const queryClassId = this.$route.query.classId;

        // if (queryClassId) {
        //   const selectedClass = this.allClasses.find(
        //     (c) => c.id === parseInt(queryClassId)
        //   );

        //   selectedClass && this.selectClass(selectedClass);
        // }
        this.classesLoading = false;
      } catch (error) {
        this.classesLoading = false;
        this.$showToastError(error.response?.data?.error);
      }
    },
  },

  async created() {
    if (this.classes) {
      return (this.allClasses = this.classes);
    }

    await this.fetchClasses();
  },
};
</script>

<style lang="scss" scoped>
.dropdown-item-wrapper {
  &::v-deep {
    .dropdown-item {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
</style>
