<script>
import { uniqueId } from "lodash";
import debounce from "../../utils/debounce";
import {
  criteriaChecker,
  MAX_COUNT_WORDS,
  getWordsCountOfAllText,
} from "./utils";
import EgFormGroup from "@/components/global/eg_form_group.vue";
import EgToggleButton from "@/components/global/eg_toggle_button.vue";
import AdvancedRubricPreviewTable from "@/components/rubrics/advanced_rubric_preview_table.vue";
import SimpleRubricPreviewTable from "@/components/rubrics/simple_rubric_preview_table.vue";
import deleteConfirmationModalMixin from "@/mixins/deleteConfirmationModal";
import ExpandableTextarea from "@/components/rubrics/rubricForm/ExpandableTextarea.vue";

const getDefaultCriteria = () => ({
  point: "",
  scale: 4,
  scales: [
    {
      id: uniqueId("temp_"),
      description: "",
      scale: 4,
    },
  ],
});

export default {
  name: "RubricForm",
  components: {
    ExpandableTextarea,
    EgToggleButton,
    EgFormGroup,
    AdvancedRubricPreviewTable,
    SimpleRubricPreviewTable,
  },
  mixins: [deleteConfirmationModalMixin],
  props: {
    noButtons: {
      type: Boolean,
      default: false,
    },
    editing: {
      type: Boolean,
      required: false,
    },
    rubric: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
  data() {
    return {
      canValidate: false,
      name: "",
      intensity: "moderate",
      scaleType: "Advanced",
      criteria: [getDefaultCriteria()],
      isPreview: false,
    };
  },

  computed: {
    buttonText() {
      return this.editing ? "Save changes" : "Create rubric";
    },
    canAddCriteriaPoint() {
      return this.criteria.length < 50;
    },
    isUsingCustomScale() {
      return ["Simple", "Advanced"].includes(this.scaleType);
    },
    isUsingAdvancedScale() {
      return this.scaleType === "Advanced";
    },
  },
  methods: {
    updateCriteriaValue(value, index) {
      this.criteria[index].point = value;
      this.debounceCheckWordsCountOfAllText();
    },
    updateCriteriaScale(scale, index) {
      this.criteria[index].scale = scale;
    },
    updateCriteriaScaleDescriptionValue(description, index, scaleIndex) {
      this.criteria[index].scales[scaleIndex].description = description;
      this.debounceCheckWordsCountOfAllText();
    },
    updateCriteriaScaleDescriptionScale(scale, index, scaleIndex) {
      this.criteria[index].scales[scaleIndex].scale = scale;
    },

    addCriteriaPoint() {
      if (this.canAddCriteriaPoint) {
        this.criteria.push(getDefaultCriteria());
      }
    },

    deleteCriteria(index) {
      this.showDeleteConfirmationModal({
        message: "Are you sure you want to delete this criteria?",
      })
        .then((value) => {
          if (value) {
            this.criteria.splice(index, 1);
          }
        })
        .catch((err) => {
          this.$showToastError("Something went wrong. Please try again.", {
            title: "Delete error",
            solid: true,
          });

          console.error(err);
        });
    },

    duplicateCriteria(index) {
      this.criteria.splice(index, 0, structuredClone(this.criteria[index]));
    },

    async addCriteriaDescription(index, scaleIndex) {
      this.criteria[index].scales.splice(scaleIndex + 1, 0, {
        description: "",
        scale: 0,
        title: "",
        id: uniqueId("temp_"),
      });
      this.$nextTick(() => {
        const ref = this.$refs[`c-${index}-s-${scaleIndex + 1}`][0];
        ref.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
          inline: "center",
        });
      });
    },

    deleteCriteriaDescription(index, scaleIndex) {
      this.criteria[index].scales.splice(scaleIndex, 1);
    },

    debounceCheckWordsCountOfAllText: debounce(function () {
      this.checkWordsCountOfAllText();
    }, 2000),

    checkWordsCountOfAllText() {
      if (getWordsCountOfAllText(this.criteria) > MAX_COUNT_WORDS) {
        this.$showToastError(
          "The maximum word count for a rubric is 25,000 words. Please reduce the word count on your rubric and try again."
        );
        return true;
      }

      return false;
    },

    // return data or false to detect error or data
    onSubmit(e) {
      e && e.preventDefault();

      this.canValidate = true;

      if (this.checkWordsCountOfAllText()) {
        return false;
      }

      if (!this.name) {
        this.$showToastError("Rubric name is required.");

        return false;
      }

      if (this.name.length > 255) {
        this.$showToastError(
          "The rubric name is too long. The maximum is 255 characters."
        );

        return false;
      }

      if (!this.criteria[0].point) {
        this.$showToastError(
          "At least one grading criteria and grade level is required."
        );

        return false;
      }

      let criterias = this.criteria.map((criteria) => {
        const criteriaData = {};

        if (this.isUsingAdvancedScale) {
          criteriaData.name = criteria.point;
        }

        criteriaData.criteriaGrades = criteria.scales.map((scale) => ({
          description: this.isUsingAdvancedScale
            ? scale.description
            : criteria.point,
          maxGrade: this.isUsingAdvancedScale ? scale.scale : criteria.scale,
          title: scale.title,
        }));

        return criteriaData;
      });

      if (this.scaleType === "Default") {
        criterias = this.criteria.map((obj) => {
          return {
            criteriaGrades: [
              {
                description: obj.point,
              },
            ],
          };
        });
      }

      const rubricData = {
        name: this.name,
        type: this.scaleType,
        intensity: this.intensity,
        criterias,
      };

      const { canCreateRubric, rubricError } = criteriaChecker({
        scaleType: this.scaleType,
        criterias: rubricData.criterias,
      });

      if (!canCreateRubric) {
        this.$showToastError(rubricError);

        return false;
      }

      this.$emit("rubricSubmitted", rubricData);

      return rubricData;
    },

    showDeleteConfirmation() {
      this.showDeleteConfirmationModal({
        message: "Are you sure you want to delete this rubric?",
      })
        .then((needDelete) => {
          needDelete && this.$emit("delete");
        })
        .catch((err) => {
          this.$showToastError("Something went wrong. Please try again.", {
            title: "Delete error",
            solid: true,
          });

          console.log(err);
        });
    },
    loadRubric() {
      // set data when the form is used for editing
      if (this.rubric) {
        this.name = this.rubric.name;
        this.scaleType = this.rubric.type;

        if (this.rubric.type === "Default" || this.rubric.type === "Simple") {
          this.criteria = this.rubric.criterias.map((criteria) => ({
            ...getDefaultCriteria(),
            point: criteria.criteriaGrades[0].description,
            scale: criteria.criteriaGrades[0].maxGrade || 0,
          }));
        }

        if (this.rubric.type === "Advanced") {
          this.criteria = this.rubric.criterias.map((criteria) => ({
            point: criteria.name,
            scale: 0,
            scales: criteria.criteriaGrades.map((scale) => ({
              description: scale.description,
              scale: scale.maxGrade,
              title: scale.title,
              id: scale.id,
            })),
          }));
        }

        if (this.$route?.query?.type === "preview") {
          this.isPreview = true;
        }
      }
    },
  },
  mounted() {
    this.loadRubric();

    // init watch after all data initials
    this.$watch("scaleType", () => {
      this.isPreview = false;
    });
  },
  watch: {
    rubric() {
      this.loadRubric();
    },
  },
};
</script>

<template>
  <div>
    <b-form @submit="onSubmit">
      <div>
        <b-row>
          <b-col md="8">
            <eg-form-group label="Rubric name" required>
              <b-form-input
                v-model="name"
                :state="canValidate ? !(name.length > 255) : null"
                placeholder="Name"
                type="text"
              />
            </eg-form-group>
          </b-col>
        </b-row>

        <div
          v-if="isUsingCustomScale"
          class="d-flex align-items-center flex-gap-1"
        >
          <b-button-group size="sm" v-model="isPreview">
            <eg-toggle-button
              class="tab-button"
              :pressed="!isPreview"
              @click="isPreview = false"
            >
              Create
            </eg-toggle-button>
            <eg-toggle-button
              class="tab-button"
              :pressed="isPreview"
              @click="isPreview = true"
            >
              Preview
            </eg-toggle-button>
          </b-button-group>
          <b-form-checkbox
            v-if="!editing"
            switch
            value="Advanced"
            unchecked-value="Simple"
            v-model="scaleType"
          >
            {{ scaleType }}
          </b-form-checkbox>
        </div>

        <div v-if="!isPreview">
          <div v-for="(criterion, index) in criteria" :key="index">
            <div class="section-criterion-name">
              <b-form-input
                v-model="criterion.point"
                size="sm"
                placeholder="Criteria Title - for example, Evidence"
                class="pale-placeholder"
                :state="canValidate ? criterion.point.length > 0 : null"
              />

              <b-form-input
                v-if="scaleType === 'Simple'"
                type="number"
                number
                :min="0"
                :max="100"
                :step="0.1"
                size="sm"
                class="grade-value"
                v-model="criterion.scale"
              />

              <b-button
                variant="outline-info"
                size="sm"
                v-b-tooltip.hover
                title="Duplicate criteria"
                @click="duplicateCriteria(index)"
              >
                <b-icon icon="back" />
              </b-button>
              <div v-b-tooltip.hover title="Remove criteria descriptor">
                <b-button
                  variant="outline-info"
                  size="sm"
                  @click="deleteCriteria(index)"
                  :disabled="criteria.length <= 1"
                >
                  <b-icon icon="trash" />
                </b-button>
              </div>
            </div>
            <div
              v-if="scaleType === 'Advanced'"
              class="section-criterion-grades"
            >
              <div
                v-for="(scale, scaleIdx) in criterion.scales"
                :key="scale.id"
                class="d-flex"
              >
                <div :ref="`c-${index}-s-${scaleIdx}`" class="criteria-grade">
                  <div class="d-flex flex-gap">
                    <b-form-input
                      v-model="scale.title"
                      size="sm"
                      placeholder="Excellent"
                      class="grade-description pale-placeholder"
                    />
                    <b-form-input
                      type="number"
                      number
                      :min="0"
                      :max="100"
                      :step="0.1"
                      size="sm"
                      class="grade-value"
                      v-model="scale.scale"
                    />
                    <b-button
                      variant="link"
                      size="sm"
                      class="p-0"
                      @click="deleteCriteriaDescription(index, scaleIdx)"
                    >
                      <b-icon icon="trash" />
                    </b-button>
                  </div>
                  <ExpandableTextarea
                    :value="scale.description"
                    @change="scale.description = $event"
                    :canValidate="canValidate"
                  />
                </div>
                <div class="d-flex flex-gap flex-column align-items-center">
                  <div class="vertical-line" />
                  <b-button
                    variant="link"
                    size="sm"
                    @click="() => addCriteriaDescription(index, scaleIdx)"
                  >
                    <b-icon icon="plus" />
                  </b-button>
                  <div class="vertical-line" />
                </div>
              </div>
              <div style="flex: 0 0 100px" />
            </div>
          </div>
          <div class="section-add-criterion">
            <b-button
              variant="outline-info"
              size="sm"
              @click="addCriteriaPoint"
            >
              <b-icon icon="plus" />
              Add criteria
            </b-button>
          </div>
        </div>
        <div v-else>
          <AdvancedRubricPreviewTable
            v-if="scaleType === 'Advanced'"
            :criteria="criteria"
          />
          <SimpleRubricPreviewTable
            v-if="scaleType === 'Simple'"
            :criteria="criteria"
          />
        </div>
      </div>

      <div v-if="!noButtons" class="mt-3 text-right">
        <b-button class="mr-2" @click="$emit('close')">Cancel</b-button>
        <b-button
          v-if="editing"
          class="mr-2"
          variant="danger"
          @click="showDeleteConfirmation"
        >
          Delete
        </b-button>
        <b-button class="avoid-support-overlap" type="submit" variant="info">{{
          buttonText
        }}</b-button>
      </div>
    </b-form>
  </div>
</template>

<style scoped>
.section-criterion-name {
  border: 1px #dfdfdf solid;
  padding: 1rem;
  display: flex;
  gap: 1rem;
  border-radius: 0 0.25rem 0 0;
  background-color: #f9f9ff;
}

.section-criterion-grades {
  background-color: #f9f9ff;
  border: #dfdfdf solid;
  border-width: 0 1px;
  padding: 1rem;
  scrollbar-width: thin;
  display: flex;
  flex-wrap: wrap;
  gap: 20px 0;
}
.section-add-criterion {
  padding: 1rem;
  border: #dfdfdf solid;
  border-width: 0 1px 1px 1px;

  border-radius: 0 0 0.25rem 0.25rem;
}
.tab-button {
  border-bottom: 0;
  &[aria-pressed="true"] {
    border: 1px #dfdfdf solid !important;
    border-bottom-width: 0 !important;
  }
}
.tab-button:first-child {
  border-bottom-left-radius: 0;
}

.tab-button:last-child {
  border-bottom-right-radius: 0;
}

.criteria-grade {
  display: inline-flex;
  flex-direction: column;
  width: 280px;
  height: 235px;
  gap: 0.5rem;
  flex: 0 0 auto;

  .grade-description {
    width: 100px;
  }
}
.grade-value {
  width: 65px;
}

.vertical-line {
  border-left: 1px #dfdfdf solid;
  height: 100%;
}
</style>
