<template>
  <div class="select-rubric">
    <eg-selectable-table
      v-model="selectedIds"
      :items="value"
      :total="value.length"
      :limit="value.length"
      :fields="fields"
    >
      <template #selectAll>
        <b-button
          variant="outline-info"
          size="sm"
          v-b-toggle.rubric-library-sidebar
          @click="focusedAssignment = null"
        >
          Bulk assign rubric
        </b-button>
      </template>

      <template #cell(selectRubric)="{ item, index }">
        {{ item.rubric ? item.rubric.name : "" }}
        <b-button
          :variant="item.rubric ? 'link' : 'outline-info'"
          size="sm"
          v-b-toggle.rubric-library-sidebar
          @click="focusedAssignment = index"
        >
          {{ item.rubric ? "Edit" : "Select rubric" }}
        </b-button>
      </template>
    </eg-selectable-table>
    <rubric-library @input="selectRubric" show-add-new />
  </div>
</template>

<script>
import EgSelectableTable from "@/components/global/eg_selectable_table";
import RubricLibrary from "@/components/rubrics/rubric_library.vue";

export default {
  components: {
    EgSelectableTable,
    RubricLibrary,
  },

  props: {
    value: {
      type: Array,
    },
  },

  data() {
    return {
      selectedIds: [],
      focusedAssignment: null,
      fields: [
        {
          key: "title",
          label: "Assignment",
          formatter: (value, key, item) => {
            return item.title;
          },
        },
        {
          key: "selectRubric",
          label: "Rubric",
        },
      ],
    };
  },

  methods: {
    // used by wizard (outside) to validate daa inside component
    validate() {
      return (
        this.value.filter((item) => item.rubric).length === this.value.length
      );
    },

    selectRubric(rubricData) {
      const newValue = [...this.value];

      if (this.focusedAssignment !== null) {
        newValue[this.focusedAssignment].rubric = rubricData;
      } else if (this.selectedIds.length > 0) {
        newValue.forEach((item) => {
          if (this.selectedIds.includes(item.id)) {
            item.rubric = rubricData;
          }
        });
      } else {
        newValue.forEach((item) => {
          item.rubric = rubricData;
        });
      }

      this.$emit("input", newValue);
    },
  },
};
</script>
