<template>
  <b-container class="imports-canvas-view">
    <b-row class="justify-content-center align-items-center">
      <b-col cols="12" md="9" lg="8" xl="7">
        <import-canvas-connect v-if="step === 1" social-name="Canvas">
          <template #extra-instructions>
            <ul>
              <li>
                We will not make any changes to Canvas without your permission.
              </li>
            </ul>
          </template>
          <template #continue-button>
            <b-button variant="info" @click="goToStep(2)">
              Continue: Allow access to Canvas
            </b-button>
          </template>
        </import-canvas-connect>

        <canvas-instruction v-else-if="step === 2" class="mt-5">
          <template #continue-button="{ canvasUrl, canvasToken, disabled }">
            <b-button
              variant="info"
              :disabled="disabled"
              @click="importDetails(canvasUrl, canvasToken)"
            >
              Continue: Import Details
            </b-button>
          </template>
        </canvas-instruction>

        <import-wizard
          v-if="step === 3"
          platform="canvas"
          title="Import from Canvas"
          :bulk="features.bulkImports"
        />
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { mapGetters } from "vuex";
import { getUser } from "@/api";
import importApi from "@/api/requests/import";
import ImportWizard from "@/components/imports/imports_wizard.vue";
import ImportCanvasConnect from "@/components/imports/imports_connect.vue";
import CanvasInstruction from "@/components/imports/canvas/canvas_instruction.vue";
const serviceName = "canvas";

export default {
  components: {
    ImportWizard,
    ImportCanvasConnect,
    CanvasInstruction,
  },

  provide() {
    return {
      serviceAccount: this.serviceAccount,
      serviceName,
    };
  },

  data() {
    return {
      step: 1,
      serviceAccount: {
        id: null,
      },
    };
  },

  computed: {
    ...mapGetters("config", ["features"]),
  },

  async created() {
    const user = this.$store.state.user;

    if (user?.canvasPersonalCredentials || user?.cid) {
      this.serviceAccount.id = user?.canvasPersonalCredentials || user?.cid;
      this.goToStep(3);
    }
  },

  methods: {
    goToStep(number) {
      this.step = number;
    },

    async importDetails(canvasUrl, canvasToken) {
      try {
        const { data } = await importApi[serviceName].createCredentials(
          canvasUrl,
          canvasToken
        );
        this.serviceAccount.id = data.id;
        const { data: userData } = await getUser("me");
        await this.$store.dispatch("setUser", userData);

        if (userData.canvasPersonalCredentials || userData.cid) {
          this.goToStep(3);
        } else {
          this.$showToastError("Please check URL and Token, then try again.");
        }
      } catch (error) {
        this.$showToastError("Please check URL and Token, then try again.");
      }
    },
  },
};
</script>
