var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-dropdown',{staticClass:"essay-actions-dropdown",attrs:{"variant":_vm.variant,"no-caret":"","dropleft":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('b-icon',{attrs:{"icon":"three-dots-vertical","aria-hidden":"true"}})]},proxy:true}])},[(_vm.$listeners.rename)?_c('b-dropdown-item',{on:{"click":function($event){return _vm.$emit('rename', _vm.essay)}}},[_vm._v(" Edit essay name ")]):_vm._e(),(_vm.$listeners.regrade)?_c('b-dropdown-item',{on:{"click":function($event){return _vm.$emit('regrade', _vm.essay)}}},[_vm._v(" Re-grade ")]):_vm._e(),(_vm.$listeners.move)?_c('b-dropdown-item',{on:{"click":function($event){return _vm.$emit('move', _vm.essay.id)}}},[_vm._v(" Move to ")]):_vm._e(),(_vm.showExportSection)?_c('b-dropdown-divider'):_vm._e(),(_vm.$listeners.print)?_c('b-dropdown-item',{on:{"click":function($event){return _vm.$emit('print', _vm.essay)}}},[_vm._v(" Print feedback ")]):_vm._e(),(
      _vm.$listeners.export &&
      _vm.essay.exportable &&
      _vm.essay.platform?.includes('google')
    )?_c('b-dropdown-item',{on:{"click":function($event){return _vm.$emit('export', { type: 'google', essay: _vm.essay })}}},[_c('div',[_vm._v("Export to Google Classroom")]),(_vm.essay.exportedAt)?_c('div',{staticClass:"small-text"},[_vm._v(" Last exported on "+_vm._s(_vm.formatDate(_vm.essay.exportedAt))+" ")]):_vm._e()]):_vm._e(),(
      _vm.$listeners.export &&
      _vm.essay.exportable &&
      _vm.essay.platform?.includes('canvas')
    )?_c('b-dropdown-item',{on:{"click":function($event){return _vm.$emit('export', { type: 'canvas', essay: _vm.essay })}}},[_c('div',[_vm._v("Export to Canvas")]),(_vm.essay.exportedAt)?_c('div',{staticClass:"small-text"},[_vm._v(" Last exported on "+_vm._s(_vm.formatDate(_vm.essay.exportedAt))+" ")]):_vm._e()]):_vm._e(),(_vm.$listeners.export)?_c('b-dropdown-item',{on:{"click":function($event){return _vm.$emit('export', { type: 'pdf', essay: _vm.essay })}}},[_c('div',[_vm._v("Export to PDF")])]):_vm._e(),(_vm.showExportSection)?_c('b-dropdown-divider'):_vm._e(),(_vm.$listeners.delete)?_c('b-dropdown-item',{attrs:{"variant":"danger"},on:{"click":_vm.onDelete}},[_vm._v(" Delete ")]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }