import axios from "axios";
import errorHandler from "./freeToolsErrorHandler";

const url = process.env.VUE_APP_EG_TOOLS_URL;

const freeToolsApi = axios.create({
  baseURL: url,
});

// successHandler just returns normal response from server
const successHandler = (res) => res;

freeToolsApi.interceptors.response.use(successHandler, errorHandler);

export default freeToolsApi;
