<template>
  <div>
    <b-badge
      class="status-badge badge-pill-queued"
      v-if="isGrading"
      pill
      variant="cyan"
    >
      <b-icon animation="spin" icon="arrow-repeat" scale="1.3"></b-icon>
      {{ item.status }}
    </b-badge>

    <b-badge
      v-else-if="item.status === 'Failed'"
      :id="`status-badge-${item.id}`"
      pill
      class="status-badge failed"
      @click.stop="show = !show"
    >
      <b-icon icon="exclamation-circle" scale="1"></b-icon>
      Grading failed
      <b-popover
        :show.sync="show"
        :target="`status-badge-${item.id}`"
        placement="top"
      >
        <template #title>
          <b-button
            @click="show = false"
            aria-label="Close"
            variant="link"
            class="ml-auto text-dark"
            size="sm"
          >
            <b-icon icon="x" scale="1"></b-icon>
          </b-button>
        </template>
        {{ popperText }}
        <br v-if="showPopoverButton" />
        <b-button
          v-if="showPopoverButton"
          class="mt-2 text-info"
          variant="link"
          @click="onClickPopoverButton"
        >
          {{ popoverButtonText }}
        </b-button>
      </b-popover>
    </b-badge>

    <b-badge
      v-else
      pill
      class="status-badge"
      :class="getClassByStatus(item.status)"
    >
      <b-icon
        v-if="item.status === 'Reviewed'"
        icon="check2"
        scale="1.3"
      ></b-icon>
      <b-icon
        v-if="item.status === 'Graded'"
        icon="check2-circle"
        scale="1.3"
      ></b-icon>
      {{ getDisplayTextForStatus(item.status) }}
    </b-badge>

    <b-badge
      v-if="item.platform && item.exportedAt"
      ref="exported"
      pill
      class="status-badge exported mt-2"
    >
      <img
        :src="LMS_PROVIDERS[item.platform]?.icon"
        :alt="LMS_PROVIDERS[item.platform]?.label"
      />
      Exported
    </b-badge>

    <b-tooltip
      v-if="item.platform && item.exportedAt"
      :target="() => $refs['exported']"
      triggers="hover"
    >
      <div>
        Last exported to {{ LMS_PROVIDERS[item.platform]?.label }} on
        {{ formatDate(item.exportedAt, "MMM D, YYYY") }}
      </div>
    </b-tooltip>
  </div>
</template>

<script>
import { unpause } from "@/api";
import { LMS_PROVIDERS, errorTable } from "@/constants";
import formatDate from "@/utils/formatDate";
import trackAndRedirectToUpgrade from "@/mixins/trackAndRedirectToUpgrade";

export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
    isGrading: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [trackAndRedirectToUpgrade],

  data() {
    return {
      LMS_PROVIDERS,
      show: false,
    };
  },

  computed: {
    showPopoverButton() {
      return (
        this.item.failureCode === "pausedLimitsExceeded" ||
        this.item.failureCode === "planWordLimitsExceeded" ||
        this.item.failureCode === "planEssayLimitsExceeded"
      );
    },
    popoverButtonText() {
      switch (this.item.failureCode) {
        case "pausedLimitsExceeded":
          return "Resume";
        case "planWordLimitsExceeded":
        case "planEssayLimitsExceeded":
          return "Upgrade your plan";

        default:
          return "";
      }
    },
    popperText() {
      switch (this.item.failureCode) {
        case "pausedLimitsExceeded":
        case "planEssayLimitsExceeded":
        case "missedPermissions:":
        case "essayTextMissed":
          return errorTable[this.item.failureCode];
        case "planWordLimitsExceeded":
          return "This essay exceeds the word count limit for your plan.";
        case "serviceError:":
          return this.item.failureReason || "";

        default:
          return "There was an unexpected error processing this essay. Please try again.";
      }
    },
  },

  methods: {
    formatDate,

    async onClickPopoverButton() {
      switch (this.item.failureCode) {
        case "pausedLimitsExceeded":
          await unpause(this);
          this.show = false;

          return;
        case "planWordLimitsExceeded":
        case "planEssayLimitsExceeded":
          return this.trackAndRedirectToUpgrade(this.item.failureCode);

        default:
          return "";
      }
    },

    getClassByStatus(status) {
      switch (status) {
        case "Rejected":
          return "rejected";
        case "Reviewed":
          return "reviewed";
        default:
          return "ready";
      }
    },

    getDisplayTextForStatus(status) {
      switch (status) {
        case "Rejected":
          return "Rejected";
        case "Reviewed":
          return "Reviewed";
        default:
          return "Ready for review";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.status-badge {
  display: inline-flex;
  height: 20px;
  padding: 4px 8px;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  border-radius: 12px;
  font-size: 10px;
  font-weight: 400;
  line-height: normal;
  width: auto;

  img,
  svg {
    height: 12px;
    width: 12px;
  }

  &.ready {
    background: var(--brand-secondary, #f3e8fc);
    color: var(--brand, hsla(257, 61%, 56%, 1));
  }

  &.reviewed {
    background: var(--Light, #f9f9ff);
    color: var(--Text-Secondary, #6c757d);
  }

  &.queued {
    background: var(--Info-bg, #ebfafc);
    color: var(--Background-Info, #17a2b8);
  }

  &.rejected {
    background: var(--Danger-bg, #ffeaec);
    color: var(--Text-Danger, #dc3545);
  }

  &.failed {
    background: var(--Danger-bg, #ffeaec);
    color: var(--Text-Danger, #dc3545);
    border: 1px solid var(--Text-Danger, #dc3545);
  }

  &.exported {
    color: var(--Text-Secondary, #6c757d);
    background: var(--Light, hsla(240, 100%, 99%, 1));
  }
}
::v-deep {
  .popover-header {
    padding: 0;
    background-color: transparent;
    border-bottom: unset;
    display: flex;

    .btn {
      position: absolute;
      top: 0px;
      right: 0px;
    }
  }
}
</style>
