export const essayExample = `Martin Luther King Jr.: A Legacy of Change

Martin Luther King Jr. was one of the most influencial figures in American history. His leadership in the Civil Rights Movement helped to bring about significant changes in laws and attitudes regarding racial equality in the United States. Through nonviolent protest, powerfull speeches, and unwavering determination, King inspired millions to stand up against injustice. His legacy contnues to shape society today.

Born on January 15, 1929, in Atlanta, Georgia, King grew up in a deeply segregated society. From an early age, he was aware of the racial injustices that African Americans faced daily. Inspired by his father, a Baptist minister, and the teachings of Mahatma Gandhi, King believed in fighting for equality through peaceful means. His education at Morehouse College, Crozer Theological Seminary, and Boston University prepared him for a life of leadership and advcacy.

One of King's most significant contributions was his role in the Montgomery Bus Boycott of 1955-1956. After Rosa Parks was arrested for refusing to give up her seat to a white passenger, King helped organize a year-long boycott of the city's bus system. The peaceful protest that ensued was successfull in leading to a Supreme Court ruling that segregation on public buses was unconstitutional. The success of the boycott marked the beginning of King's national prominence as a civil rights leader, and he was always motivated.

King is perhaps best known for his famous "I Have a Dream" speech, delivered on August 28, 1963, during the March on Washington. In this speech, he called for an end to racism and for equality among all people, regardless of race. His words resonated across the nation and helped pave the way for the Civil Rights Act of 1964, which banned discrimination based on race, color, religion, sex, or national origin. However, some people believed this act would be enough to fix racial inequality immediately, which was a misbelief.

Despite his peaceful approach, King faced constant threats, arrests, and acts of violence. Nevertheless, he remained committed to nonviolence and continued to push for justice. In 1965, he led the Selma to Montgomery marches, which highlighted the need for voting rights for African Americans. The results of these efforts was the passage of the Voting Rights Act of 1965, ensuring that racial discrimination in voting was prohibited. However, some areas of the country still resisted changes, making it seem like Kings efforts were in vain.

Tragically, King's life was cut short when he was assassinated on April 4, 1968, in Memphis, Tennessee. His death was a devastating loss, but his impact remains undeniable. Today, Martin Luther King Jr. is honored with a national holiday, and his teachings continue to inspire movements for social justice around the world. His teachings were meaninful and still impact many, and the world changed completely overnight after his passing.

In conclusion, Martin Luther King Jr. dedicated his life to fighting racial inequality through nonviolent resistance. His leadership, courage, and vision helped transform American society, making it more just and inclusive. His dream of equality is still relevant today, reminding us that we all have a role to play in creating a fair and just world. Despite this, many injustices still exist today, leaving one to wonder if his dream can ever truly be achieved.`;
