<script>
import EgDropdown from "@/components/global/eg_dropdown.vue";
import EgToggleButton from "@/components/global/eg_toggle_button.vue";
import UppyDashboard from "@/components/dashboard/uppy_dashboard.vue";
import { getFreeToolsRubrics } from "@/api/requests/freeTools";

import { essayExample } from "./essayExample";

const gradeIntensities = [
  { text: "Easy", value: "light" },
  { text: "Normal", value: "moderate" },
  { text: "Strict", value: "intense" },
];

const essayOptions = [
  { text: "Upload a file", value: "files" },
  { text: "Enter text", value: "text" },
];

const gradeLevels = ["Elementary", "Middle School", "High School", "College"];

export default {
  name: "UploadEssayStep",
  components: { UppyDashboard, EgToggleButton, EgDropdown },
  props: {
    selectedEssayOption: String,
    essayContents: String,
    selectedGradingLevel: String,
    selectedGradingIntensity: String,
    files: Array,
  },
  emits: [
    "selectEssayOption",
    "setEssayContent",
    "selectGradingLevel",
    "selectRubric",
    "selectGradingIntensity",
    "grade",

    "file-added",
    "file-removed",
    "file-uploaded",
  ],
  data() {
    return {
      gradeIntensities,
      essayOptions,
      gradeLevels,
      essayExample,
      registerLink: "https://app.essaygrader.ai/register",
      transloaditTemplateId:
        process.env.VUE_APP_ASSIGNMENTS_TRANSLOADIT_TEMPLATE_ID,
      rubrics: [],
      selectedRubric: null,
    };
  },
  watch: {
    selectedRubric(v) {
      this.$emit("selectRubric", v);
    },
  },

  methods: {
    async fetchRubrics() {
      const { data } = await getFreeToolsRubrics();
      this.rubrics = data;
      const selectedRubricId = process.env.VUE_APP_FREE_TOOLS_DEFAULT_RUBRIC_ID;
      this.selectedRubric =
        data.find((rubric) => rubric.id === Number(selectedRubricId)) ||
        data[0];
    },

    upload() {
      return this.$refs.uppyDashboard.upload();
    },
  },
  async mounted() {
    await this.fetchRubrics();
  },
};
</script>

<template>
  <div>
    <div class="step-1-layout mb-3">
      <div>
        <b>Student submission</b>
        <div class="d-flex align-items-center flex-gap mt-2">
          <b-button
            class="outline use-sample"
            @click="
              $emit('setEssayContent', essayExample);
              $emit('selectEssayOption', 'text');
            "
          >
            Insert sample essay
          </b-button>
          <b-button
            class="outline"
            @click="
              $emit(
                'selectEssayOption',
                selectedEssayOption === 'files' ? 'text' : 'files'
              );
              $emit('setEssayContent', '');
            "
          >
            {{ essayOptions[selectedEssayOption === "files" ? 1 : 0].text }}
          </b-button>
        </div>
      </div>
      <div class="uppy-container">
        <uppy-dashboard
          class="uppy"
          v-if="selectedEssayOption === 'files'"
          ref="uppyDashboard"
          :value="files"
          plugin-name-camera-title="Handwriting"
          @input="$emit('file-uploaded', $event)"
          :acceptServices="['googleDrive', 'oneDrive', 'dropbox']"
          :max-number-of-files="1"
          :transloadit-template-id="transloaditTemplateId"
          @file-added="$emit('file-added', $event)"
          @file-removed="$emit('file-removed', $event)"
        />
        <b-form-textarea
          v-if="selectedEssayOption === 'text'"
          class="textarea"
          :value="essayContents"
          placeholder="Enter submission here"
          @update="$emit('setEssayContent', $event)"
        />
      </div>
      <div>
        <div>
          <b>Customize your grading: </b>
        </div>
        <div>
          Select the grade level, rubric, and grading intensity for this essay.
        </div>
      </div>

      <div>
        <div class="rubric-panel">
          <b>Grade level</b>
          <div class="grading-buttons-row mt-2">
            <eg-toggle-button
              v-for="level in gradeLevels"
              :key="level"
              :pressed="selectedGradingLevel === level"
              @click="$emit('selectGradingLevel', level)"
              size="sm"
            >
              {{ level }}
            </eg-toggle-button>
          </div>
          <div class="mb-3">
            <eg-dropdown>
              <template #button-content>
                <div class="d-flex align-items-center justify-content-between">
                  <div
                    v-if="selectedRubric"
                    class="d-flex flex-column align-items-start"
                  >
                    <span>{{ selectedRubric.name }}</span>
                    <span>
                      Created by
                      <img
                        src="@/assets/eg-logo-tiny-transparent.png"
                        alt="EssayGrader"
                        class="logo"
                      />
                    </span>
                  </div>
                  <div class="d-flex flex-column align-items-start" v-else>
                    <span style="line-height: 3">Select rubric to grade</span>
                  </div>
                  <b-icon class="ml-auto" icon="chevron-down"></b-icon>
                </div>
              </template>
              <b-dropdown-item-button
                v-for="rubric in rubrics"
                :key="rubric.id"
                class=""
                @click="() => (selectedRubric = rubric)"
              >
                {{ rubric.name }}
                <br />
                Created by
                <img
                  src="@/assets/eg-logo-tiny-transparent.png"
                  alt="EssayGrader"
                  class="logo"
                />
              </b-dropdown-item-button>
              <b-dropdown-divider />
              <b-button
                :href="registerLink"
                size="sm"
                class="outline mx-4 my-1"
                target="_blank"
              >
                Create custom rubric
              </b-button>
            </eg-dropdown>
          </div>
          <div>
            <b>Grading intensity</b>
            <div class="grading-buttons-row mt-2 pb-0">
              <eg-toggle-button
                v-for="level in gradeIntensities"
                :key="level.value"
                :pressed="selectedGradingIntensity === level.value"
                @click="$emit('selectGradingIntensity', level.value)"
                size="sm"
              >
                {{ level.text }}
              </eg-toggle-button>
            </div>
          </div>
        </div>
        <b-button
          :disabled="
            (selectedEssayOption === 'files' && files.length === 0) ||
            (selectedEssayOption === 'text' && !essayContents)
          "
          @click="$emit('grade')"
          class="button large mt-3 grade-button"
        >
          Grade
        </b-button>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.step-1-layout {
  display: grid;

  grid-template: auto auto / 1fr 1fr;
  grid-auto-flow: column;
  grid-gap: 1rem;
}

@media (max-width: 900px) {
  .step-1-layout {
    grid-template: auto 300px auto auto / auto;
  }
}

.uppy-container {
  container-type: size;
}

.uppy ::v-deep {
  .uppy-Dashboard-inner,
  .uppy-Dashboard-innerWrap,
  .uppy-Dashboard-AddFiles {
    border-radius: 1rem;
  }

  .uppy-Dashboard {
    height: 100cqh;
  }
  .uppy-Dashboard-poweredBy {
    display: none !important;
  }

  .uppy-Dashboard-inner {
    background: #ffffff;
  }

  .uppy-Dashboard-AddFiles {
    border: 1px solid #e6e6e6;
  }

  .uppy-Dashboard-AddFiles-list {
    [data-uppy-acquirer-id="MyDevice"] {
      display: none;
    }
  }
}

.textarea {
  height: 100cqh;
  border-radius: 1rem;
  resize: none;
  border: 1px solid #e6e6e6;
}

.grading-buttons-row {
  display: flex;
  gap: 1rem;
  padding-bottom: 1rem;
  flex-wrap: wrap;
}

.logo {
  width: 30px;
  height: 20px;
  margin-top: -3px;
}

.grade-button {
  width: 100%;
}

button.btn.use-sample {
  background: #6a50f3;
  color: #fff;
}

.form-control:focus {
  box-shadow: none;
  border-color: #e2e2e2;
}

.dropdown::v-deep .btn,
.btn {
  border-radius: 0.5rem;
}

.btn-secondary:not(:disabled):not(.disabled).active:focus {
  box-shadow: none;
}
</style>
