import axios from "axios";
// eslint-disable-next-line no-unused-vars
import { h } from "preact";
import { UIPlugin } from "@uppy/core";
import { GooglePickerView } from "@uppy/provider-views";
import { GoogleDriveIcon } from "@uppy/provider-views/lib/GooglePicker/icons.js";
import { RequestClient, tokenStorage } from "@uppy/companion-client";

const locale = {
  strings: {
    pluginNameGoogleDrivePicker: "Google Drive",
  },
};
const DRIVE_FILE_FIELDS =
  "kind,id,imageMediaMetadata,name,mimeType,ownedByMe,size,modifiedTime,iconLink,thumbnailLink,teamDriveId,videoMediaMetadata,exportLinks,shortcutDetails(targetId,targetMimeType)";

export default class GoogleDrivePicker extends UIPlugin {
  static VERSION = "0.3.2";

  static requestClientId = GoogleDrivePicker.name;

  type = "acquirer";

  icon = GoogleDriveIcon;

  defaultLocale = locale;

  constructor(uppy, opts) {
    super(uppy, opts);
    this.id = this.opts.id || "GoogleDrivePicker";
    this.storage = this.opts.storage || tokenStorage;

    this.defaultLocale = locale;
    this.i18nInit();
    this.title = this.i18n("pluginNameGoogleDrivePicker");

    const client = new RequestClient(uppy, {
      pluginId: this.id,
      provider: "url",
      companionUrl: this.opts.companionUrl,
      companionHeaders: this.opts.companionHeaders,
      companionCookiesRule: this.opts.companionCookiesRule,
    });

    this.uppy.registerRequestClient(GoogleDrivePicker.requestClientId, client);
  }

  install() {
    const { target } = this.opts;
    if (target) {
      this.mount(target, this);
    }
  }

  uninstall() {
    this.unmount();
  }

  #handleFilesPicked = async (files, accessToken) => {
    const filesPromises = files.map(async ({ id, mimeType, name, ...rest }) => {
      const size = await getGoogleFileSize({ id, token: accessToken });

      return {
        source: this.id,
        name,
        type: mimeType,
        data: {
          size,
        },
        isRemote: true,
        remote: {
          companionUrl: this.opts.companionUrl,
          url: `${this.opts.companionUrl}/google-picker/get`,
          body: {
            fileId: id,
            accessToken,
            ...rest,
          },
          requestClientId: GoogleDrivePicker.requestClientId,
        },
      };
    });

    this.uppy.addFiles(await Promise.all(filesPromises));
  };

  render = () =>
    h(GooglePickerView, {
      storage: this.storage,
      pickerType: "drive",
      uppy: this.uppy,
      clientId: this.opts.clientId,
      apiKey: this.opts.apiKey,
      appId: this.opts.appId,
      onFilesPicked: this.#handleFilesPicked,
    });
}

const getClient = async ({ token }) =>
  axios.create({
    baseURL: "https://www.googleapis.com/drive/v3",
    headers: {
      authorization: `Bearer ${token}`,
    },
  });

function isShortcut(mimeType) {
  return mimeType === "application/vnd.google-apps.shortcut";
}

function isGsuiteFile(mimeType) {
  return mimeType && mimeType.startsWith("application/vnd.google");
}

async function getStats({ id, token }) {
  const client = await getClient({ token });

  const getStatsInner = async (statsOfId) =>
    client
      .get(`files/${encodeURIComponent(statsOfId)}`, {
        params: { fields: DRIVE_FILE_FIELDS, supportsAllDrives: true },
      })
      .then(({ data }) => data);

  const stats = await getStatsInner(id);

  // If it is a shortcut, we need to get stats again on the target
  if (isShortcut(stats.mimeType))
    return getStatsInner(stats.shortcutDetails.targetId);
  return stats;
}

async function getGoogleFileSize({ id, token }) {
  const { mimeType, size } = await getStats({ id, token });

  if (!size && isGsuiteFile(mimeType)) {
    // GSuite file sizes cannot be predetermined (but are max 10MB)
    // e.g. Transfer-Encoding: chunked
    return undefined;
  }

  return parseInt(size, 10);
}
