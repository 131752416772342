import freeToolsApi from "@/api/freeToolsApi";
import sampleFreeToolsResponse from "@/api/samples/freeTools";

export const getFreeToolsRubrics = () => {
  return freeToolsApi.get("/rubrics", { params: { isUsedInFreeTools: true } });
};

export const freeToolGrade = async (payload) => {
  try {
    return await freeToolsApi.post("/free-tools/grade", payload);
  } catch (error) {
    return { data: sampleFreeToolsResponse };
  }
};
