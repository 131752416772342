<template>
  <div class="eg-stepper">
    <div class="steps-progress px-2 px-md-4 mb-4">
      <div class="d-flex justify-content-center mb-4">
        <div
          class="step d-flex align-items-center justify-content-center"
          v-for="(step, index) in steps"
          :key="index"
        >
          <b-icon
            v-if="step.completed"
            icon="check"
            class="completed"
            variant="success"
          />
          <template v-else>
            <img
              v-if="currentStepIndex === index"
              src="@/assets/icons/eg-stepper-step-active.svg"
              alt=""
            />
            <img v-else src="@/assets/icons/eg-stepper-step.svg" alt="" />
          </template>
          <div v-if="index !== steps.length - 1" class="divider"></div>
        </div>
      </div>
      <h5 class="title text-center mb-4" v-if="steps[currentStepIndex].title">
        {{ steps[currentStepIndex].title }}
      </h5>
    </div>

    <div @keydown.enter="enterPressed" class="steps-body px-2 px-md-4">
      <div v-for="(_, slotName, index) in slots" :key="slotName">
        <div v-if="index === currentStepIndex">
          <slot :name="slotName"></slot>
        </div>
      </div>

      <div class="stepper-actions d-flex justify-content-end mt-5">
        <b-button :disabled="currentStepIndex === 0" @click="goPrev">
          Previous
        </b-button>
        <b-button class="ml-3" variant="info" :disabled="value" @click="goNext">
          <b-spinner v-if="value" small></b-spinner>
          {{
            this.currentStepIndex === this.steps.length - 1
              ? finishLabel
              : "Next"
          }}
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },

    stepsTitles: {
      type: Array,
      default: () => [],
    },

    finishLabel: {
      type: String,
      default: "Finish",
    },

    enterGoesNext: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      steps: [],
      currentStepIndex: 0,
    };
  },

  computed: {
    slots() {
      return this.$slots;
    },
  },

  created() {
    this.steps = Object.values(this.$slots).map((_, index) => ({
      title: this.stepsTitles[index],
      completed: false,
    }));
  },

  methods: {
    enterPressed() {
      if (this.enterGoesNext) {
        this.goNext();
      }
    },
    goTo(index) {
      this.currentStepIndex = index;
    },

    async sendBeforeChangeEvent() {
      if (!this.$listeners.beforeChange) return Promise.resolve();

      return await new Promise((resolve, reject) => {
        this.$emit("beforeChange", {
          index: this.currentStepIndex,
          resolve,
          reject,
        });
      });
    },

    async goPrev() {
      const index = Math.max(0, this.currentStepIndex - 1);
      const prevSlot = this.$slots?.[`slot-${this.currentStepIndex}`]?.[0];

      if (prevSlot?.componentOptions?.listeners?.loaded) {
        this.$emit("input", true);
      }

      this.steps[index].completed = false;
      this.goTo(index);
    },

    async goNext() {
      if (this.value) return;

      const nextSlot = this.$slots?.[`slot-${this.currentStepIndex + 2}`]?.[0];

      if (nextSlot?.componentOptions?.listeners?.loaded) {
        this.$emit("input", true);
      }

      try {
        await this.sendBeforeChangeEvent();

        if (this.currentStepIndex === this.steps.length - 1) {
          this.$emit("input", true);
          try {
            await new Promise((resolve) => {
              this.$emit("finish", resolve);
            });
            this.$emit("input", false);
          } catch (error) {
            this.$emit("input", false);
          }

          return;
        }

        this.steps[this.currentStepIndex].completed = true;
        this.goTo(Math.min(this.steps.length - 1, this.currentStepIndex + 1));
        this.$nextTick(() => {
          // focus on the first input field when navigating to the next step
          this.$el.querySelector("input")?.focus();
        });
      } catch {
        this.$emit("input", false);
        console.log("Stepper: reject on beforeChange");
      }
    },
  },
};
</script>

<style scoped lang="scss">
.steps-progress {
  border-bottom: 1px solid #dfdfdf;
}

.step {
  cursor: pointer;

  & img {
    width: 14px;
    height: 14px;
    margin-top: 1px;
    margin-bottom: 1px;
  }
}

.completed {
  font-size: 16px;
}

.divider {
  width: 40px;
  height: 1px;
  background-color: #dfdfdf;
  margin: 0 8px;
}
</style>
