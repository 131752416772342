<script>
import { freeToolGrade } from "@/api/requests/freeTools";
import UploadEssayStep from "@/components/free-tools/grade/UploadEssayStep.vue";
import FeedbackStep from "@/components/free-tools/grade/FeedbackStep.vue";
import LoadingStep from "@/components/free-tools/grade/LoadingStep.vue";

export default {
  name: "FreeToolGrade",
  components: {
    LoadingStep,
    FeedbackStep,
    UploadEssayStep,
  },
  data() {
    return {
      selectedEssayOption: "text",
      selectedGradingLevel: "Middle School",
      selectedGradingIntensity: "moderate",
      expired: false,
      blocked: false,
      essayContents: "",
      files: [],
      selectedRubric: null,
      messagePort: null,
      lastHeightUpdate: null,
      step: 0,
      rubrics: [],
      feedback: null,
      errors: null,
    };
  },
  methods: {
    notifyResize: function () {
      const height = window.document.body.scrollHeight;
      if (this.lastHeightUpdate !== height) {
        this.messagePort?.postMessage({ height });
        this.lastHeightUpdate = height;
      }
    },

    handleMessage(e) {
      if (e.data === "notifyResize") {
        this.messagePort = e.ports[0];
        this.notifyResize();
      }
    },

    async grade() {
      try {
        const payload = {
          gradeLevel: this.selectedGradingLevel,
          intensity: this.selectedGradingIntensity,
          rubricId: this.selectedRubric.id,
        };
        if (this.selectedEssayOption === "text") {
          payload.text = this.essayContents;
        } else {
          await this.$refs.uploadStep.upload();
          payload.files = this.files.map((file) => file.fileUrl);
        }
        this.step = 1;
        const {
          data: { feedback, errors, content },
        } = await freeToolGrade(payload);
        this.feedback = feedback.replaceAll("\n", "");
        this.errors = errors.replaceAll("\n", "");
        this.essayContents = content;

        this.step = 2;
      } catch (error) {
        this.step = 0;
      } finally {
        this.$nextTick(() => {
          this.notifyResize();
        });
      }
    },

    reset() {
      this.files = [];
      this.essayContents = "";
      this.step = 0;
      this.selectedEssayOption = "text";
    },
  },
  beforeCreate() {
    window.$crisp.push(["do", "chat:hide"]);
  },
  async mounted() {
    window.addEventListener("resize", this.notifyResize);
    window.addEventListener("message", this.handleMessage, false);
    window.document.body.classList.add("free-tools");
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.notifyResize);
    window.removeEventListener("message", this.handleMessage);
    window.document.body.classList.remove("free-tools");
  },
};
</script>

<template>
  <div class="homepage-tool">
    <UploadEssayStep
      v-if="step === 0"
      :selectedEssayOption="selectedEssayOption"
      @selectEssayOption="selectedEssayOption = $event"
      :essayContents="essayContents"
      @setEssayContent="essayContents = $event"
      :files="files"
      @file-added="files = [$event]"
      @file-removed="files = []"
      @file-uploaded="files = $event"
      :selectedGradingLevel="selectedGradingLevel"
      @selectGradingLevel="selectedGradingLevel = $event"
      @selectRubric="selectedRubric = $event"
      :selectedGradingIntensity="selectedGradingIntensity"
      @selectGradingIntensity="selectedGradingIntensity = $event"
      @grade="grade"
      ref="uploadStep"
    />
    <LoadingStep v-else-if="step === 1" />
    <FeedbackStep
      v-else-if="step === 2"
      :selectedRubric="selectedRubric"
      :essayContents="essayContents"
      :feedback="feedback"
      :errors="errors"
      @reset="reset"
    />
  </div>
</template>

<style>
body.free-tools {
  background-color: transparent !important;
}
</style>

<style scoped lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400..700;1,400..700&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");

.homepage-tool {
  font-family: "Montserrat", serif;
  font-optical-sizing: auto;
  font-style: normal;
  font-size: 16px;

  ::v-deep {
    b,
    .strong {
      font-weight: 500;
    }

    .outline {
      border: 1px solid #6a50f3;
      border-radius: 10px;
      color: #6a50f3;
      background: #ffffff;
      &:focus {
        text-decoration: none;
        box-shadow: none;
      }
    }

    .button {
      font-size: 18px;
      color: #ffffff;
      background: #6a50f3;
      border-radius: 10px;
      &:focus {
        text-decoration: none;
        box-shadow: none;
      }
    }
    .large {
      padding: 14px 28px;
    }

    .rubric-panel {
      border: 1px solid #e6e6e6;
      border-radius: 1rem;
      padding: 24px;
      background: #ffffff;
    }

    .scroll-wrapper {
      overflow: auto;
      height: 100%;
    }
  }
}

.loading-text {
  font-family: "Lora", serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
}
</style>
