import axios from "axios";
import store from "@/store";
import errorHandler from "./errorHandler";

const url = process.env.VUE_APP_EG_API_URL;

const headers = {};
if (store.state.token) {
  headers["Authorization"] = `Bearer ${store.state.token}`;
}

const api = axios.create({
  baseURL: url,
  headers,
});

// successHandler just returns normal response from server
const successHandler = (res) => res;

api.interceptors.response.use(successHandler, errorHandler);

export default api;
