import api from "@/api/api";

const getExportPayload = (essaysIds = [], fields = [], filters = {}) => {
  const payload = { fields };

  if (essaysIds.length > 0) {
    payload.essaysIds = essaysIds;
  } else {
    payload.filters = Object.keys(filters).reduce((acum, filterKey) => {
      if (filterKey === "createdAt") {
        acum[`${filterKey}From`] = filters[filterKey]["from"];
        acum[`${filterKey}To`] = filters[filterKey]["to"];

        return acum;
      }

      if (filters[filterKey]) {
        acum[filterKey] = filters[filterKey];
      }

      return acum;
    }, {});
  }

  return payload;
};

export const exportToPlatform = (
  platform,
  { essaysIds = [], fields = [], filters = {} }
) => {
  const payload = getExportPayload(essaysIds, fields, filters);

  return api.put(`/essays/export/${platform}`, payload);
};

export const exportToPdf = ({ essaysIds = [], fields = [], filters = {} }) => {
  const payload = getExportPayload(essaysIds, fields, filters);

  return api.put("/essays/export/pdf", payload, {
    responseType: "blob",
  });
};
export const exportToCsv = ({ essaysIds = [], fields = [], filters = {} }) => {
  const payload = getExportPayload(essaysIds, fields, filters);

  return api.put("/essays/export/csv", payload, {
    responseType: "blob",
  });
};
