import Vue from "vue";

export const forms = Vue.observable({});

// add entity form here
const formComponents = {
  rubric: () =>
    import("../views/new_rubric_view.vue").then((module) => module.default),
  class: () =>
    import("../views/new_class_view.vue").then((module) => module.default),
  assignment: () =>
    import("../views/assignments/new_assignment_view.vue").then(
      (module) => module.default
    ),
};

function closeForm(entity) {
  if (!forms?.[entity]) return;

  Vue.delete(forms, entity);
}

const normalizeOptions = async (entity, options = {}) => {
  let forceComponent = options.forceComponent
    ? options.forceComponent
    : formComponents[entity];

  if (!forceComponent) {
    console.error("No force component for entity - ", entity);
  }

  if (typeof forceComponent === "function") {
    forceComponent = await forceComponent();
  }

  const callback = (...arg) => {
    if (!options.callback) {
      return closeForm(entity);
    }

    const result = options.callback(...arg);
    closeForm(entity);

    return result;
  };

  return {
    listeners: {},
    props: {},
    callbackEvent: "input",
    ...options,
    callback,
    forceComponent,
  };
};
export default function initShowToastError(Vue) {
  // options
  // callback - a function to be called when the form is closed or emited specific event (default: () => {})
  // callbackEvent - when emitted, the callback function will be called with the $event object as argument (default: 'input')
  // forceComponent - force component that need rendered (default: null)
  // props - can send any props (default: null)
  // listeners - can send any vue listeners (default: null)
  Vue.prototype.$openForm = async function (entity, options) {
    options = await normalizeOptions(entity, options);

    Vue.set(forms, entity, {
      ...options,
      forceComponent: options.forceComponent,
    });
  };

  Vue.prototype.$closeForm = function (entity) {
    if (!forms?.[entity]) return;
    Vue.delete(forms, entity);
  };
}
