import { render, staticRenderFns } from "./advanced_rubric_preview_table.vue?vue&type=template&id=9967643c&scoped=true&"
import script from "./advanced_rubric_preview_table.vue?vue&type=script&lang=js&"
export * from "./advanced_rubric_preview_table.vue?vue&type=script&lang=js&"
import style0 from "./advanced_rubric_preview_table.vue?vue&type=style&index=0&id=9967643c&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9967643c",
  null
  
)

export default component.exports