<template>
  <b-card class="px-2 px-md-4 py-1">
    <div class="text-center">
      <img
        class="mr-2"
        src="../../../assets/icons/canvas-full.svg"
        alt="Canvas"
      />
      <div class="mt-4 mb-3 title">Allow access to Canvas</div>
    </div>

    <b-alert class="default_color" variant="default" show>
      Need some help?
      <a
        href="https://egcdn.nyc3.cdn.digitaloceanspaces.com/videos/EssayGrader_CanvasTokenCreation.mp4"
        target="_blank"
        >Watch the demo video</a
      >
    </b-alert>

    <eg-additional-collapse
      class="canvas_collapse"
      label="Step one: Copy and paste your Canvas URL"
      accordion="accordion"
      :value="true"
    >
      <ul class="mb-0">
        <li>
          In a new tab or window, <b>log into Canvas</b> and <b>copy</b> the URL
          from the browser’s address bar (highlighted in pink below).
          <img
            class="canvas_instruction_img"
            src="../../../assets/illustrations/canvas-instruction.jpg"
            alt="Canvas Instruction"
          />
        </li>
        <li><b>Paste</b> the URL below.</li>
      </ul>

      <eg-form-group label="Canvas URL">
        <b-form-input
          type="text"
          v-model="canvasUrl"
          placeholder="Paste Canvas URL here"
          :state="canvasURLState"
        />
        <b-form-text v-if="canvasURLState === null">
          The Canvas site URL for your domain. Example:
          https://k12.instructure.com
        </b-form-text>
        <b-form-valid-feedback v-if="canvasURLState">
          Looks good!
        </b-form-valid-feedback>
      </eg-form-group>
    </eg-additional-collapse>
    <eg-additional-collapse
      class="canvas_collapse mt-3"
      label="Step two: Create an Access Token"
      accordion="accordion"
    >
      <ul class="mb-0">
        <li>
          In <b>Account > Settings</b>, create an Access Token by selecting
          <b>New Access Token.</b>
        </li>

        <li>In the <b>Purpose</b> field, add “EssayGrader”.</li>
        <li>Leave the <b>Expires</b> field blank.</li>
        <li><b>Generate</b> and <b>copy</b> the token.</li>
        <li><b>Paste</b> the token below.</li>
      </ul>
      <eg-form-group label="Access Token">
        <b-form-input
          type="text"
          v-model="canvasToken"
          placeholder="Paste Access Token here"
          :state="canvasTokenState"
        />
        <b-form-text v-if="canvasTokenState === null">
          Required field
        </b-form-text>
        <b-form-valid-feedback v-if="canvasTokenState">
          Looks good!
        </b-form-valid-feedback>
      </eg-form-group>
    </eg-additional-collapse>

    <div class="text-right mt-2">
      <slot name="cancel-button"></slot>
      <slot
        name="continue-button"
        :canvas-url="canvasUrl"
        :canvas-token="canvasToken"
        :disabled="!canvasURLState || !canvasTokenState"
      >
        <b-button
          variant="info"
          :disabled="!canvasURLState || !canvasTokenState"
          @click="importDetails(canvasUrl, canvasToken)"
        >
          Continue
        </b-button>
      </slot>
    </div>
  </b-card>
</template>

<script>
import isURL from "validator/es/lib/isURL";
import { getUser } from "@/api";
import importApi from "@/api/requests/import";

import EgAdditionalCollapse from "@/components/global/eg_additional_collapse.vue";
import EgFormGroup from "@/components/global/eg_form_group.vue";

export default {
  components: {
    EgAdditionalCollapse,
    EgFormGroup,
  },

  data() {
    return {
      canvasUrl: "",
      canvasToken: "",
    };
  },

  computed: {
    canvasURLState() {
      return isURL(this.canvasUrl) ? true : null;
    },

    canvasTokenState() {
      return this.canvasToken.trim().length > 0 ? true : null;
    },
  },

  methods: {
    async importDetails(canvasUrl, canvasToken) {
      try {
        const { data } = await importApi["canvas"].createCredentials(
          canvasUrl,
          canvasToken
        );
        this.serviceAccount.id = data.id;
        const { data: userData } = await getUser("me");
        await this.$store.dispatch("setUser", userData);

        if (userData.canvasPersonalCredentials || userData.cid) {
          this.$emit("updated");
        } else {
          this.$showToastError("Please check URL and Token, then try again.");
        }
      } catch (error) {
        this.$showToastError("Please check URL and Token, then try again.");
      }
    },
  },
};
</script>

<style scoped lang="scss">
.title {
  color: var(--Text-Body, #212529);
  font-family: "Inter";
  font-size: 32px;
  font-weight: 500;
  line-height: 38px;
}

.default_color {
  background: #d1ecf1;
}
.canvas_instruction_img {
  width: 243px;
  transform: translateX(-3px);
}

.canvas_collapse {
  font-family: "Inter";

  &::v-deep {
    .btn {
      color: var(--Text-Primary, #007bff);
      font-size: 20px;
      font-weight: 500;
      line-height: 24px;
    }
  }
}
</style>
