<template>
  <div class="integrations-view">
    <section class="p-3">
      <template>
        <b-row>
          <b-col cols="12">
            <div class="d-block d-md-flex align-items-center">
              <h1 class="title">Integration settings</h1>
            </div>
          </b-col>
        </b-row>
        <b-row v-if="!loading" class="mb-3 mt-2">
          <b-col v-if="oauthProfiles.length">
            <b-card
              v-for="profile in oauthProfiles"
              :key="profile.id"
              class="my-2"
            >
              <div class="card-title d-flex align-items-center">
                <div class="d-flex align-items-center">
                  <img
                    class="option-icon mr-1"
                    :src="providers[profile.provider]?.icon"
                    alt=""
                  />
                  {{ providers[profile.provider]?.label }}
                </div>
                <b-button
                  v-if="!profile.isSignIn"
                  variant="outline-info"
                  size="sm"
                  class="ml-auto"
                  @click="unlink(profile.id)"
                >
                  Unlink
                </b-button>
              </div>
              <div class="card-content">
                <div>
                  <div class="d-flex align-items-center">
                    <b class="field-col">Email</b>
                    <div>{{ profile.userPublicIdentifier }}</div>
                  </div>
                  <div v-if="profile.url" class="d-flex align-items-center">
                    <b class="field-col">URL</b>
                    <div>{{ profile.url }}</div>
                  </div>
                </div>
              </div>
              <template
                v-if="
                  profile.provider === 'canvasPersonal' && features?.bulkImports
                "
              >
                <div class="divider"></div>
                <div class="card-content">
                  <b-button
                    variant="outline-info"
                    size="sm"
                    class="ml-auto"
                    to="/imports/canvas"
                  >
                    Import new Assignments
                  </b-button>
                  <div class="mt-2">
                    <b class="d-flex"
                      >Import/Export settings for this account</b
                    >
                    <div class="mt-2">
                      <b-form-checkbox
                        :value="autoGradeSubmission"
                        @input="
                          updateSettings({
                            type: 'autoGradeSubmission',
                            value: $event,
                          })
                        "
                        name="auto-grade-submission"
                        switch
                      >
                        Automatically grade new submissions
                      </b-form-checkbox>
                    </div>
                  </div>
                </div>
              </template>
            </b-card>
          </b-col>
          <b-col v-else>
            <empty-state
              title="No integrations."
              description="Third party integrations will be listed here."
            ></empty-state>
          </b-col>
        </b-row>
      </template>
    </section>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { LMS_PROVIDERS } from "@/constants";
import { getUserOauthProfiles, deleteUserOauthProfile, getUser } from "@/api";
import EmptyState from "@/components/global/empty_state.vue";

export default {
  components: {
    EmptyState,
  },

  data() {
    return {
      providers: LMS_PROVIDERS,
      loading: true,
      oauthProfiles: [],
      autoGradeSubmission: false,
    };
  },

  computed: {
    ...mapGetters("config", ["features"]),
    user() {
      return this.$store.state.user;
    },
  },

  created() {
    this.init();
  },

  methods: {
    updateSettings({ type, value }) {
      console.log(type, value);
    },

    async unlink(profileId) {
      await deleteUserOauthProfile(this.user.id, profileId);
      this.oauthProfiles = this.oauthProfiles.filter(
        (profile) => profile.id !== profileId
      );
      const { data } = await getUser("me");
      await this.$store.dispatch("setUser", data);
    },

    async init() {
      this.loading = true;
      try {
        const {
          data: { oauthProfiles },
        } = await getUserOauthProfiles(this.user.id);
        this.oauthProfiles = oauthProfiles;
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.field-col {
  width: 50px;
}
</style>
