<script>
import { updateEssay, getClasses } from "@/api";

export default {
  name: "MoveEssayModal",
  props: {
    essayId: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  data() {
    return {
      loading: true,
      selectedClass: null,
      classOptions: [{ value: null, text: "Select class" }],
    };
  },
  methods: {
    async moveEssay() {
      try {
        this.loading = true;

        const { data } = await updateEssay(this.essayId, {
          ClassId: this.selectedClass,
        });

        this.essay = data;

        this.loading = false;
        this.error = "";

        this.$emit("essayMoved");
      } catch (error) {
        this.loading = false;
        this.$emit("moveError", error.response?.data?.error);
      }
    },
    async fetchClasses() {
      // safeguard to ensure we don't fetch for classes
      // more than once.
      if (this.classOptions.length === 1) {
        try {
          const { data } = await getClasses();

          data.classes.forEach((foundClass) => {
            this.classOptions.push({
              text: foundClass.name,
              value: foundClass.id,
            });
          });

          this.loading = false;
          this.error = "";
        } catch (error) {
          this.loading = false;
          this.error = error.response?.data?.error;
        }
      }
    },
  },
};
</script>

<template>
  <b-modal
    id="move-essay-modal"
    title="Move essay to a new class"
    ok-title="Move"
    ok-variant="info"
    size="lg"
    @show="fetchClasses"
    @ok="moveEssay"
  >
    <b-spinner v-if="loading"></b-spinner>
    <div v-else>
      <p v-if="!classOptions.length > 1">No classes found</p>
      <b-form-group
        id="class-select"
        description="Only the last 15 classes created will appear."
        label="Select a class to move this essay to:"
        label-for="class-form-select"
      >
        <b-form-select
          id="class-form-select"
          v-model="selectedClass"
          :options="classOptions"
        ></b-form-select>
      </b-form-group>
    </div>
  </b-modal>
</template>
