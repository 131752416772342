<template>
  <div v-if="loading">
    <b-skeleton width="25%"></b-skeleton>
    <b-skeleton type="input"></b-skeleton>
  </div>
  <div v-else>
    <eg-selectable-table
      v-if="bulk"
      :value="selectedIds"
      :items="classes"
      :total="classes.length"
      :limit="classes.length"
      :fields="fields"
      @input="selectClasses"
    >
      <template v-slot:selectAll="{ selectedAll, selectAll }">
        <b-checkbox
          :checked="selectedAll"
          @change="selectAll"
          variant="info"
          :indeterminate="
            selectedIds.length > 0 && selectedIds.length < classes.length
          "
        >
          Select all classes
        </b-checkbox>
      </template>
    </eg-selectable-table>
    <eg-form-group v-else label="Class" required>
      <eg-search-select
        entity="class"
        title-key="name||descriptionHeading"
        :items="classes"
        :value="getSelectedClass()"
        @input="selectClasses([$event.id])"
      >
        <template #no-items>
          <div>
            No classes were found on your account. You can only import
            assignments from classes created in {{ serviceLabel }}.
          </div>
        </template>
      </eg-search-select>
    </eg-form-group>
  </div>
</template>

<script>
import importApi from "@/api/requests/import";
import EgFormGroup from "@/components/global/eg_form_group.vue";
import EgSearchSelect from "@/components/global/eg_search_select.vue";
import errorHandler from "./errorHandler";
import EgSelectableTable from "@/components/global/eg_selectable_table";
import { LMS_PROVIDERS } from "@/constants";

export default {
  components: {
    EgFormGroup,
    EgSearchSelect,
    EgSelectableTable,
  },

  props: {
    value: {
      type: Array,
    },
    bulk: {
      type: Boolean,
      default: false,
    },
  },

  inject: ["serviceAccount", "serviceName"],

  data() {
    return {
      loading: true,
      selectedIds: [],
      classes: [],
      fields: [
        {
          key: "name",
          label: "Class",
          formatter: (value, key, item) => {
            return item.name;
          },
        },
      ],
    };
  },

  computed: {
    serviceLabel() {
      return LMS_PROVIDERS?.[this.serviceName]?.label || "LMS";
    },
  },

  methods: {
    getSelectedClass() {
      return (
        this.classes.find((classData) => classData.id === this.value[0]) || null
      );
    },

    selectClasses(ids) {
      this.selectedIds = ids;
      this.$emit("input", this.selectedIds);
    },

    async init() {
      try {
        this.loading = true;
        const { data } = await importApi[this.serviceName].getClasses(
          this.serviceAccount.id
        );
        this.classes = data.classes;

        let selectedClassesIds = this.classes.length
          ? [this.classes[0].id]
          : [];

        if (this.value) {
          const classesIds = this.classes
            .filter((c) => this.value.includes(c.id))
            .map((c) => c.id);

          if (classesIds) {
            selectedClassesIds = classesIds;
          }
        }

        selectedClassesIds && this.selectClasses(selectedClassesIds);
        this.loading = false;
        this.$emit("loaded");
      } catch (error) {
        this.loading = false;
        this.$emit("loaded");
        errorHandler({
          vm: this,
          error,
          message:
            "There was a problem fetching classes from your account. Please try again.",
        });
      }
    },
  },

  created() {
    this.init();
  },
};
</script>
