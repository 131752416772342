<template>
  <div class="cell-name">
    <span v-if="isGrading" class="font-rubik">
      {{ item.name }}
    </span>
    <a
      v-else
      :href="`/essay/${item.id}`"
      target="_blank"
      class="font-rubik essay-title"
      >{{ item.name }}</a
    >

    <div v-if="!isGrading" class="d-flex align-items-center">
      <div
        v-if="item.Student"
        class="additional-text"
        v-b-tooltip.hover
        :title="`${item.Student.firstName} ${item.Student.lastName}`"
      >
        By {{ item.Student.firstName }} {{ item.Student.lastName }}
      </div>
      <svg
        v-if="item.Student"
        class="mx-1"
        xmlns="http://www.w3.org/2000/svg"
        width="4"
        height="4"
        viewBox="0 0 4 4"
        fill="none"
      >
        <circle cx="2" cy="2" r="2" fill="#6C757D" />
      </svg>
      <div class="additional-text" v-if="showGrade">Grade {{ item.grade }}</div>
      <svg
        v-if="showGrade"
        class="mx-1"
        xmlns="http://www.w3.org/2000/svg"
        width="4"
        height="4"
        viewBox="0 0 4 4"
        fill="none"
      >
        <circle cx="2" cy="2" r="2" fill="#6C757D" />
      </svg>
      <div class="additional-text mr-1">
        {{ formatDate(item.createdAt) }}
      </div>
    </div>
  </div>
</template>

<script>
import formatDate from "@/utils/formatDate";

export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
    isGrading: {
      type: Boolean,
      default: false,
    },
    showGrade: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    formatDate,
  },
};
</script>

<style lang="scss" scoped>
.cell-name {
  color: var(--Text-Body, #212529);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  max-width: 270px;
  overflow: hidden;
  white-space: normal;
  overflow-wrap: break-word;

  @media (min-width: 1024px) {
    max-width: 360px;
  }

  @media (min-width: 1366px) {
    max-width: 560px;
  }

  a {
    color: var(--Text-Body, #212529);
  }
}

.additional-text {
  color: var(--gray, #6c757d);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
