<template>
  <div class="essays-table">
    <essays-table-filters
      :total="total"
      :limit="limit"
      :selected-ids="selectedIds"
      :available-filters="availableFilters"
      :selected-all="selectedAll"
      :items-to-select-counts="essays.filter((item) => !isGrading(item)).length"
      @filter-changed="$emit('getData', $event)"
      @export="showExportModal"
      @delete="$emit('delete', $event)"
      @selectedAll="$emit('selectedAll', $event)"
    />

    <eg-selectable-table
      v-model="selectedIds"
      class="essay-table w-100"
      selectable
      split-select
      :items="essays"
      :fields="fields"
      :total="total"
      :limit="limit"
      :current-page="currentPage"
      :get-disabled="isGrading"
      :loading="tableLoading"
      :initial-loading="initialLoading"
      :click-action="(item) => this.$router.push({ path: `/essay/${item.id}` })"
      @onChangePage="$emit('onChangePage', $event)"
    >
      <template #empty-state>
        <empty-state
          v-if="!tableLoading && isFiltered && !essays.length"
          key="filtered"
          title="No essays found."
          description="Try again or clear all filters to view results."
          class="m-auto"
        >
        </empty-state>
        <empty-state
          v-if="!tableLoading && !isFiltered && !essays.length"
          key="empty"
          title="No essays have been graded yet."
          description="Use AI to grade your essays. Depending on the complexity of the
            text, essay grade times will vary. Happy grading!"
          button-text="Grade new essay"
          button-path="/"
          class="m-auto"
        >
          <img
            style="max-height: 230px"
            src="../../assets/illustrations/empty-state-essays.svg"
            alt="EssayGrader Empty State"
          />
        </empty-state>
      </template>
      <template #head()="{ label, field }">
        {{ label }}
        <b-icon
          v-if="field.sortable"
          :style="{ color: sortBy === field.key ? '#343A40' : 'unset' }"
          :icon="
            sortBy === field.key
              ? `sort-${sortDesc ? 'down' : 'up'}-alt`
              : 'sort-down-alt'
          "
        />
      </template>
      <template #head(actions)="{ label }">
        {{ label }}
      </template>
      <template #table-colgroup="{ fields }">
        <col
          v-for="field in fields"
          :key="field.key"
          :class="field.columnClass"
        />
      </template>

      <template #cell(status)="{ item }">
        <cell-status :item="item" :is-grading="isGrading(item)" />
      </template>
      <template #cell(name)="{ item }">
        <cell-name :item="item" :is-grading="isGrading(item)" />
      </template>
      <template #cell(grade)="{ item }">
        <cell-grade :item="item" />
      </template>
      <template #cell(Rubric)="{ item }">
        <span v-if="item.Rubric" class="small">
          <a :href="`/rubric/edit/${item.Rubric.id}?type=preview`" class="link">
            {{ item.Rubric.name || `#${item.Rubric.id}` }}
          </a>
        </span>
        <span v-else-if="item.type" class="small">
          {{ formatDefaultType(item) }}
        </span>
        <span v-else class="text-gray small">None</span>
      </template>
      <template #cell(Assignment)="{ item }">
        <span v-if="item.Assignment" class="small">
          <a :href="`/assignment/edit/${item.Assignment.id}`" class="link">
            {{ item.Assignment.title || `#${item.Assignment.id}` }}
          </a>
        </span>
        <span v-else class="text-gray small">None</span>
      </template>
      <template #cell(Class)="{ item }">
        <span v-if="item.Class" class="small">
          <a :href="`/class/${item.Class.id}`" class="link">
            {{ item.Class.name }}
          </a>
        </span>
        <span v-else class="text-gray small">None</span>
      </template>
      <template #cell(actions)="{ item }">
        <essay-actions-dropdown
          v-if="!isGrading(item)"
          :essay="item"
          @move="$emit('move', $event)"
          @delete="$emit('delete', $event)"
          @regrade="regradeHandler"
          @export="showExportModal"
          @rename="onRename"
        />
      </template>
    </eg-selectable-table>

    <essay-regrade-modal
      v-if="selectedEssay"
      :essay="selectedEssay"
      @finishRegrade="$emit('getData')"
    />

    <essay-rename-modal
      :essay="selectedEssay"
      @hide="selectedEssay = null"
      @updated="$emit('getData')"
    />

    <export-to-canvas-modal
      :selected-essays="selectedAll ? [] : selectedEssays"
      :export-filters="filters"
      @hide="selectedEssays = []"
    />

    <export-to-classroom-modal
      :selected-essays="selectedAll ? [] : selectedEssays"
      :export-filters="filters"
      @hide="selectedEssays = []"
    />

    <export-to-pdf-modal
      :selected-ids="selectedAll ? [] : selectedEssays.map((essay) => essay.id)"
      :filters="filters"
      @hide="selectedEssays = []"
    />

    <export-to-csv-modal
      :selected-ids="selectedAll ? [] : selectedEssays.map((essay) => essay.id)"
      :filters="filters"
      @hide="selectedEssays = []"
    />
  </div>
</template>

<script>
import { getIntensityDisplayText, formatDefaultType } from "@/utils/format";
import CellStatus from "@/components/essay/table/cell_status.vue";
import CellName from "@/components/essay/table/cell_name.vue";
import CellGrade from "@/components/essay/table/cell_grade.vue";
import EgSelectableTable from "@/components/global/eg_selectable_table";
import EssayActionsDropdown from "./essay_actions_dropdown.vue";
import EssayRegradeModal from "./essay_regrade_modal.vue";
import EssayRenameModal from "@/components/essay/modals/essay_rename_modal.vue";
import EssaysTableFilters from "./filters/essays_table_filters.vue";
import EmptyState from "../global/empty_state.vue";
import regradeMixin from "@/mixins/regradeMixin";
import ExportToClassroomModal from "@/components/essay/modals/export_to_classroom.vue";
import ExportToCanvasModal from "@/components/essay/modals/export_to_canvas.vue";
import ExportToPdfModal from "@/components/essay/modals/export_to_pdf.vue";
import ExportToCsvModal from "@/components/essay/modals/export_to_csv.vue";

const DEFAULT_FIELDS = [
  {
    key: "status",
    label: "Status",
    columnClass: "col-status",
    sortable: false,
  },
  {
    key: "name",
    label: "Essay",
    tdClass: "text-truncate",
    columnClass: "col-name",
    sortable: false,
  },
  {
    key: "Class",
    label: "Class",
    columnClass: "col-class",
    sortable: false,
  },
  {
    key: "Assignment",
    label: "Assignment",
    columnClass: "col-assignment",
    sortable: false,
  },
  {
    key: "Rubric",
    label: "Rubric",
    columnClass: "col-rubric",
    sortable: false,
  },
  {
    key: "grade",
    label: "Grade",
    columnClass: "col-grade",
    sortable: false,
  },
  {
    key: "actions",
    label: "",
    tdClass: "text-right",
    columnClass: "col-actions",
  },
];

export default {
  name: "EssaysTable",
  components: {
    EgSelectableTable,
    EssayActionsDropdown,
    EssayRenameModal,
    EssayRegradeModal,
    EssaysTableFilters,
    EmptyState,
    CellStatus,
    CellName,
    CellGrade,
    ExportToClassroomModal,
    ExportToCanvasModal,
    ExportToPdfModal,
    ExportToCsvModal,
  },

  mixins: [regradeMixin],

  props: {
    essays: {
      type: Array,
      required: true,
    },
    availableFilters: {
      type: Array,
      default: () => [
        "name",
        "class",
        "rubric",
        "assignment",
        "createdAt",
        "studentName",
      ],
    },
    fields: {
      type: Array,
      required: false,
      default: () => DEFAULT_FIELDS,
    },
    total: {
      type: Number,
      required: true,
    },
    limit: {
      type: Number,
      required: true,
    },
    currentPage: {
      type: Number,
      required: true,
    },
    selectedAll: {
      type: Boolean,
      required: true,
    },
    sortBy: {
      type: String,
    },
    sortDesc: {
      type: Boolean,
      required: false,
      default: false,
    },
    tableLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
    initialLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
    filters: {
      type: Object,
      default: () => ({}),
    },
    isFiltered: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      selectedIds: [],
      selectedEssays: [],
      selectedEssay: null,
    };
  },

  watch: {
    "selectedIds.length"() {
      this.$emit("selectedAll", false);
    },
  },

  created() {
    this.$watch(
      () => {
        return this.total + this.limit + this.currentPage;
      },
      () => {
        this.selectedIds = [];
      }
    );
  },

  methods: {
    showExportModal({ type, essay }) {
      this.selectedEssays = essay
        ? [essay]
        : this.selectedIds.map((id) => this.essays.find((e) => e.id === id));

      this.$nextTick(() => {
        this.$bvModal.show(`export-to-${type}-modal`);
      });
    },
    onRename(essay) {
      this.selectedEssay = essay;
      this.$nextTick(() => {
        this.$bvModal.show("essay-rename-modal");
      });
    },

    async regradeHandler(essay) {
      this.selectedEssay = essay;
      const regraded = await this.regrade(essay);
      regraded && this.$emit("getData");
    },
    isGrading(item) {
      return item.status === "Queued";
    },

    getIntensityDisplayText(intensity) {
      return getIntensityDisplayText(intensity);
    },

    formatDefaultType(essay) {
      return formatDefaultType(essay);
    },
  },
};
</script>

<style lang="scss" scoped>
.link {
  color: #343a40;
}

.essays-table {
  &::v-deep {
    --table-height: unset;
    --min-table-height: unset;
    --table-header-height: 48px;
    --max-row-height: calc(
      var(--table-height) - var(--table-header-height) - 2px
    );

    .b-table-sticky-header {
      max-height: 100%;
      min-height: var(--min-table-height);
      height: var(--table-height);
      margin-bottom: 0;
    }

    .b-table-bottom-row > td {
      height: var(--max-row-height);
    }

    .selectable-table {
      margin-top: 0 !important;
      border: 1px solid hsl(0deg, 0%, 87%);
      border-top: none;
      border-radius: 0.25rem;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      /* TODO Correct calculate height of table */
      height: var(--table-height);
      min-height: var(--min-table-height);

      @media (max-width: 768px) {
        border-top: 1px solid hsl(0deg, 0%, 87%);
        border-top-left-radius: 0.25rem;
        border-top-right-radius: 0.25rem;
      }

      & .table tr td {
        padding-top: 16px;
        padding-bottom: 16px;
      }

      & .table tr.b-table-top-row td {
        padding: 0;
      }

      & .table tr th {
        color: var(--Background-Secondary, #6c757d);
      }
    }

    @media (max-width: 768px) {
      .selectable-table .table tr th:nth-child(4),
      .selectable-table .table tr td:nth-child(4),
      .selectable-table .table tr th:nth-child(5),
      .selectable-table .table tr td:nth-child(5),
      .selectable-table .table tr th:nth-child(6),
      .selectable-table .table tr td:nth-child(6),
      .selectable-table .table tr th:nth-child(7),
      .selectable-table .table tr td:nth-child(7) {
        display: none;
      }
    }
  }
}

.badge-pill {
  width: 83px;
  color: white;
  text-align: left;

  svg {
    margin-right: 3px;
  }
}

.badge-cyan {
  background-color: #17a2b8;
}

.badge-secondary {
  background-color: #6c757d;
}

.col-select {
  width: 48px;
}

.col-status {
  width: 135px;
}

.col-name {
  max-width: 50%;
}

.col-rubric {
  max-width: 12%;

  @media (min-width: 992px) {
    max-width: 12%;
  }
}

.col-class {
  max-width: 12%;
}

.col-actions {
  width: 38px;

  @media (min-width: 992px) {
    width: 70px;
  }
}

.skeleton_loading_bar {
  background-color: rgba(0, 0, 0, 0.25);
  height: 20px;
}

.text-gray {
  color: var(--gray, #6c757d);
}

.essay-skeleton-details {
  height: 16px;
}
</style>
