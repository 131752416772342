import api from "@/api/api";

export const getUser = (userId) => {
  return api.get(`/users/${userId}`);
};

export const updateUser = (payload) => {
  return api.post("/user", payload);
};

export const getUserOauthProfiles = (userId) => {
  return api.get(`/users/${userId}/oauth-profiles`);
};

export const deleteUserOauthProfile = (userId, profileId) => {
  return api.delete(`/users/${userId}/oauth-profiles/${profileId}`);
};
