<script>
import getBreadcrumbs from "./breadcrumbs";
import Breadcrumbs from "@/components/global/breadcrumbs.vue";
import AssignmentForm from "@/components/assignment/assignment_form.vue";

export default {
  name: "NewAssignmentView",

  emits: ["input", "close"],

  components: {
    Breadcrumbs,
    AssignmentForm,
  },

  props: {
    isRedirectAfterCreated: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      breadcrumbs: getBreadcrumbs("New assignment"),
    };
  },

  methods: {
    handleClose() {
      if (this.$listeners["close"]) {
        this.$emit("close");
      } else {
        this.$router.push("/assignments");
      }
    },
  },
};
</script>

<template>
  <b-container>
    <section>
      <breadcrumbs :items="breadcrumbs" />
      <b-row>
        <b-col lg="6">
          <h1>New assignment</h1>
        </b-col>
      </b-row>
      <assignment-form
        :is-redirect-after-created="isRedirectAfterCreated"
        @input="$emit('input', $event)"
        @close="handleClose"
      />
    </section>
  </b-container>
</template>
