function getCookieValue(name) {
  const regex = new RegExp(`(^| )${name}=([^;]+)`);
  const match = document.cookie.match(regex);
  if (match) {
    return match[2];
  }
}
export default function loginByCookieIfNeeded(store) {
  const token = getCookieValue("token");

  if (token) {
    store.dispatch("setToken", token);
    document.cookie = "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/";
  }
}
