<script>
import { getRubrics, getSystemRubrics } from "@/api";
import EmptyState from "@/components/global/empty_state.vue";
import RubricFilter from "./rubric_filter.vue";
import EgSelectableTable from "@/components/global/eg_selectable_table";
import { formatAuthorValue } from "@/utils/format";

export default {
  name: "RubricLibraryView",

  components: {
    EmptyState,
    RubricFilter,
    EgSelectableTable,
  },

  props: {
    value: {
      type: Object,
    },

    showAddNew: {
      type: Boolean,
      default: false,
    },
  },

  emits: ["selectRubric"],

  data() {
    return {
      rubricLibrarySearchQuery: "",
      selfRubricSearchQuery: "",
      sharedRubricSearchQuery: "",
      rubricsLoading: false,
      rubrics: [],
      sharedRubrics: [],
      platformRubrics: [],
      fields: [
        {
          key: "name",
          label: "Rubric name",
          class: "name-row",
        },
        {
          key: "type",
          label: "Type",
        },
        {
          key: "author",
          label: "Created by",
          formatter: (value) => formatAuthorValue(value, this.$store),
        },
        {
          key: "action",
          label: "",
          formatter: (value, key, item) => {
            return {
              action: (item) => {
                this.$emit("selectRubric", item);
              },
              label: item.id === this.value?.id ? "Selected" : "Select",
              props: {
                variant: "outline-info",
                class: { "button-secondary-info": item.id === this.value?.id },
              },
            };
          },
        },
      ],
    };
  },

  computed: {
    groupedRubrics() {
      return this.rubrics.reduce((acc, rubric) => {
        const type = rubric.type;

        if (!acc[type]) {
          acc[type] = [];
        }

        acc[type].push(rubric);

        return acc;
      }, {});
    },

    filteredRubricLibrary() {
      return this.platformRubrics.filter((rubric) =>
        rubric.name
          .toLowerCase()
          .includes(this.rubricLibrarySearchQuery.toLowerCase())
      );
    },

    filteredSelfRubrics() {
      return this.rubrics.filter((rubric) =>
        rubric.name
          .toLowerCase()
          .includes(this.selfRubricSearchQuery.toLowerCase())
      );
    },
    filteredSharedRubrics() {
      return this.sharedRubrics.filter((rubric) =>
        rubric.name
          .toLowerCase()
          .includes(this.sharedRubricSearchQuery.toLowerCase())
      );
    },
  },

  methods: {
    onClickCreateRubric() {
      this.$openForm("rubric", {
        props: { isRedirectAfterCreated: false },
        callback: this.afterCreatedHandler,
      });
    },

    async afterCreatedHandler(value) {
      this.rubrics = await this.fetchRubrics(true);
      this.$emit("selectRubric", value);
    },

    async fetchRubrics(my) {
      try {
        const { data } = await getRubrics(my);

        return data;
      } catch (error) {
        this.$showToastError(error.response?.data?.error);
      }
    },
    async fetchSystemRubrics() {
      try {
        const { data } = await getSystemRubrics();
        return data;
      } catch (error) {
        this.$showToastError(error.response?.data?.error);
      }
    },
  },

  async mounted() {
    this.rubricsLoading = true;
    const [my, shared, system] = await Promise.all([
      this.fetchRubrics(true),
      this.fetchRubrics(false),
      this.fetchSystemRubrics(),
    ]);
    this.rubrics = my;
    this.sharedRubrics = shared;
    this.platformRubrics = system;

    this.rubricsLoading = false;

    if (this.$route.query.rubricId) {
      this.$refs.sidebarRef.localShow = true;
      const rubric = this.rubrics.find(
        (rubric) => +rubric.id === +this.$route.query.rubricId
      );

      rubric && this.$emit("selectRubric", rubric);
    }
  },
};
</script>

<template>
  <b-skeleton-wrapper :loading="rubricsLoading">
    <template #loading>
      <b-skeleton-table />
    </template>
    <b-tabs nav-class-wrapper="mx-3">
      <b-tab title="Platform rubrics" :active="rubrics.length === 0">
        <rubric-filter
          v-model="rubricLibrarySearchQuery"
          :showAddNew="showAddNew"
          @click:create="onClickCreateRubric"
        />
        <eg-selectable-table
          :selectable="false"
          :items="filteredRubricLibrary"
          :fields="fields"
        >
        </eg-selectable-table>
      </b-tab>
      <b-tab title="My rubrics" :active="rubrics.length > 0">
        <rubric-filter
          v-model="selfRubricSearchQuery"
          :show-add-new="showAddNew"
          @click:create="onClickCreateRubric"
        />
        <eg-selectable-table
          v-if="rubrics.length > 0"
          :selectable="false"
          :items="filteredSelfRubrics"
          :fields="fields"
        >
        </eg-selectable-table>
        <empty-state
          v-else-if="showAddNew"
          title="You haven’t created any rubrics yet."
          description=""
          class="mt-4 ml-auto mr-auto"
        >
          <img
            src="../../assets/illustrations/empty-state-rubrics.svg"
            alt="EssayGrader Empty State"
          />
          <template #buttons>
            <b-button variant="outline-info" @click="onClickCreateRubric">
              Create new rubric
            </b-button>
          </template>
        </empty-state>
      </b-tab>
      <b-tab title="Shared rubrics" v-if="sharedRubrics.length > 0">
        <rubric-filter
          v-model="sharedRubricSearchQuery"
          :show-add-new="showAddNew"
          @click:create="onClickCreateRubric"
        />
        <eg-selectable-table
          :selectable="false"
          :items="filteredSharedRubrics"
          :fields="fields"
        >
        </eg-selectable-table>
      </b-tab>
    </b-tabs>
  </b-skeleton-wrapper>
</template>

<style lang="scss" scoped>
.title-text {
  color: var(--Text-Body, #212529);
  font-size: 24px;
  font-weight: 500;
  line-height: 28px;
}

.rubric-sidebar {
  &::v-deep {
    .nav-tabs {
      padding: 0 1rem;
    }

    #rubric-library-sidebar {
      height: calc(100vh - var(--navbar-height));
      top: var(--navbar-height);
    }

    .b-table-sticky-header {
      max-height: calc(100vh - 155px);
      margin-bottom: 0;
      padding: 0 16px;
    }

    .name-row {
      max-width: 210px;
      overflow: hidden;
    }
  }
}
</style>
