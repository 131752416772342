<template>
  <div class="box">
    <b-form @submit="onSubmit" class="essay-submission-form d-flex">
      <div class="essay-contents w-100">
        <eg-form-group
          id="class-group"
          label="Class"
          description="Optional. Use to organize essays."
        >
          <class-select v-model="selectedClass" withSearch />
        </eg-form-group>

        <assignment-select
          v-model="selectedAssignment"
          description="Optional. Use to add writing prompts and source materials."
          withSearch
        />

        <eg-form-group label="Rubric" required>
          <b-button
            v-if="!selectedRubric"
            variant="outline-info"
            size="sm"
            v-b-toggle.rubric-library-sidebar
          >
            Choose rubric from library
          </b-button>
          <div v-if="selectedRubric" class="d-flex align-items-start">
            <div>
              <div class="rubric-name">{{ selectedRubric?.name }}</div>
              <div class="rubric-created-by">
                Created by
                <b>
                  {{
                    formatAuthorValue(selectedRubric?.author, $store) || "Me"
                  }}
                </b>
              </div>
            </div>
            <b-button
              v-if="selectedRubric"
              class="ml-4"
              variant="outline-info"
              size="sm"
              v-b-toggle.rubric-library-sidebar
            >
              Choose a different rubric
            </b-button>
          </div>
        </eg-form-group>

        <div
          v-if="!loading && !permissionsLoading && canNotUseAdvancedRubric"
          class="essay-contents w-100"
        >
          <b-alert variant="warning" show>
            You have reached your limits for advanced rubric grading this month.
            Upgrade for unlimited use of Advanced Rubrics.
            <br />
            <b-button
              variant="info"
              class="mt-2 essay-submit-btn"
              @click="trackAndRedirectToUpgrade('advanced_rubrics')"
            >
              Click here to Upgrade
            </b-button>
          </b-alert>
        </div>

        <template v-else>
          <div class="upload-area-container">
            <upload-content-area
              ref="uploadArea"
              class="upload-content-area"
              label="Upload essay(s)"
              required
              v-model="uploadType"
              :max-number-of-files="canBulkUpload ? 1000 : 1"
              @input-files="files = $event"
              @input-text="essayContents = $event"
            >
              <template #description>
                <div v-if="!canBulkUpload">
                  <b-button
                    @click="trackAndRedirectToUpgrade('bulk_upload')"
                    variant="link"
                    class="px-0 py-0 account-link"
                  >
                    Upgrade to the Pro or Premium plan
                  </b-button>
                  to upload more than 1 essay at a time.
                </div>
              </template>
            </upload-content-area>

            <div v-if="submitting" class="upload-overlay">
              <b-spinner small class="uploading-spinner"></b-spinner>
              <span class="uploading-text">Uploading...</span>
            </div>

            <eg-form-group
              id="name-input-group"
              v-if="!isFileUpload"
              label="Name"
              label-for="name-input"
              required
            >
              <b-form-input
                id="name-input"
                v-model="name"
                type="text"
                placeholder="Enter the essay name"
              ></b-form-input>
            </eg-form-group>

            <eg-additional-collapse label="More grading options">
              <div>
                <essay-language-select v-model="selectedLanguageId" />

                <rubric-intensity-radio-group
                  :disabled="!isAdvancedRubric"
                  horizontal
                  v-model="rubricIntensity"
                />
                <p v-if="!isAdvancedRubric" class="text-muted small mb-0">
                  Select an Advanced Rubric to change the grading intensity.
                </p>
              </div>
            </eg-additional-collapse>
          </div>
        </template>

        <b-button
          type="submit"
          variant="info"
          :disabled="loading || submitting"
          class="w-25 mt-2 float-right essay-submit-btn"
        >
          <template v-if="loading || submitting">
            <b-spinner small></b-spinner> Uploading...
          </template>
          <template v-else> Grade essay </template>
        </b-button>
      </div>
    </b-form>
  </div>
</template>

<script>
import { getClasses, getSingleRubric, getUsage } from "@/api";
import RubricIntensityRadioGroup from "../rubrics/rubric_intensity_radio_group.vue";
import EgFormGroup from "../global/eg_form_group.vue";
import EgAdditionalCollapse from "@/components/global/eg_additional_collapse.vue";
import EssayLanguageSelect from "@/components/dashboard/essay_language_select.vue";
import UploadContentArea from "./upload_content_area.vue";
import AssignmentSelect from "@/components/essay/assignment_select.vue";
import ClassSelect from "@/components/classes/class_select";
import trackAndRedirectToUpgrade from "@/mixins/trackAndRedirectToUpgrade";
import { formatAuthorValue } from "@/utils/format";

const getAdditionalPayload = ({ name, content, fileUrl }) => {
  const additionalPayload = {
    name,
  };

  if (content) {
    additionalPayload.content = content;
  }

  if (fileUrl) {
    additionalPayload.fileUrl = fileUrl;
  }

  return additionalPayload;
};

const defaultFormState = {
  name: "",
  essayContents: "",
  files: [],
  rubricIntensity: "moderate",
  selectedRubric: null,
  selectedLanguageId: null,
  selectedAssignmentId: null,
  tda: {},
};

export default {
  name: "SubmissionForm",

  mixins: [trackAndRedirectToUpgrade],

  components: {
    AssignmentSelect,
    EgAdditionalCollapse,
    EssayLanguageSelect,
    EgFormGroup,
    RubricIntensityRadioGroup,
    UploadContentArea,
    ClassSelect,
  },

  props: {
    selectedRubric: {
      type: Object,
    },
    submitting: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["selectRubric", "essaySubmitted"],

  data() {
    return {
      name: defaultFormState.name,
      essayContents: defaultFormState.essayContents,
      files: defaultFormState.files,
      rubricIntensity: defaultFormState.rubricIntensity,
      selectedLanguageId: defaultFormState.selectedLanguageId,
      tda: defaultFormState.tda,
      selectedAssignment: JSON.parse(
        localStorage.getItem("suggestion_form:selectedAssignment")
      ),

      // defaultSelectedRubric: defaultFormState.selectedRubric,
      uploadType: "files",
      loading: false,

      selectedClass: JSON.parse(
        localStorage.getItem("suggestion_form:selectedClass")
      ),
      classes: [],
      classesLoading: false,
      permissionsLoading: false,
      showRubricLibrary: false,
      canGradeAdvanced: false,
    };
  },

  watch: {
    selectedAssignment: {
      handler(value) {
        localStorage.setItem(
          "suggestion_form:selectedAssignment",
          JSON.stringify(this.selectedAssignment)
        );
        this.setDefaultRubricForAssignment(value?.defaultRubricId);
      },
      immediate: true,
    },

    selectedClass() {
      localStorage.setItem(
        "suggestion_form:selectedClass",
        JSON.stringify(this.selectedClass)
      );
    },

    selectedRubric() {
      this.rubricIntensity = this?.selectedRubric?.intensity || "moderate";
    },
  },

  methods: {
    async setDefaultRubricForAssignment(rubricId) {
      if (!rubricId) {
        return;
      }

      const { data } = await getSingleRubric(rubricId);
      this.$emit("selectRubric", data);
    },
    rubricGroupFunction(rubrics) {
      return [
        { id: "my", title: "My Rubric", items: rubrics },
        {
          id: "platform",
          title: "Platform rubrics",
          items: this.platformRubrics,
        },
      ];
    },
    formatAuthorValue,
    resetForm() {
      this.files = [];
    },

    showErrorToast(message) {
      this.$showToastError(message);
    },

    async onSubmit(e) {
      e.preventDefault();

      if (this.canNotUseAdvancedRubric) {
        return this.showErrorToast(
          "You need to upgrade to a Pro or Premium plan to grade with Advanced Rubrics."
        );
      }

      if (!this.selectedRubric?.id) {
        return this.showErrorToast("Select a rubric to continue.");
      }

      let payload = {
        name: this.name,
        gradeLevel: this.selectedRubric.gradeLevel,
        type: this.selectedRubric.essayType,
        LanguageId: this.selectedLanguageId,
      };

      if (this.essayContents) {
        payload.content = this.essayContents;
      }

      if (this.selectedClass) {
        payload.ClassId = this.selectedClass.id;
      }

      if (this.rubricIntensity) {
        payload.intensity = this.rubricIntensity;
      }

      if (this.selectedRubricId && this.useCustomRubrics) {
        payload = {
          ...payload,
          gradeLevel: "custom",
          type: "custom",
          RubricId: this.selectedRubricId,
        };
      }

      if (this.selectedAssignment) {
        payload.AssignmentId = this.selectedAssignment.id;
      }

      if (this.isAdvancedRubric && this.tda.sources) {
        payload.tdaSources = this.tda.sources;
      }

      if (this.isAdvancedRubric && this.tda.instructions) {
        payload.tdaInstructions = this.tda.instructions;
      }

      try {
        this.loading = true;
        await this.$refs.uploadArea.upload();
        this.loading = false;
      } catch (error) {
        this.loading = false;
        this.showErrorToast(error.response?.data?.error);

        return;
      }

      if (this.canSubmit) {
        // uploading bulk essay files
        if (this.files.length > 1) {
          try {
            this.loading = true;

            const bulkPayload = this.files.map((file) => {
              const additionalPayload = getAdditionalPayload(file);

              return {
                ...payload,
                ...additionalPayload,
              };
            });

            this.$emit("essaySubmitted", bulkPayload);

            this.loading = false;
          } catch (error) {
            this.loading = false;
            this.showErrorToast(error.response?.data?.error);
          }
        }

        // uploading single essay file
        if (this.files.length === 1) {
          try {
            this.loading = true;

            const additionalPayload = getAdditionalPayload(this.files[0]);

            this.loading = false;

            this.$emit("essaySubmitted", [
              { ...payload, ...additionalPayload },
            ]);
          } catch (error) {
            this.loading = false;
            this.showErrorToast(error.response?.data?.error);
          }
        }

        // uploading plain text essay
        if (this.essayContents) {
          this.$emit("essaySubmitted", [payload]);
        }
      } else {
        return this.showErrorToast(
          "Error: You are missing form data, ensure that you have provided an essay name and the essay contents."
        );
      }

      this.resetForm();
    },
    async getPermission() {
      try {
        this.permissionsLoading = true;
        const {
          data: {
            advancedRubricEssaysUsage: { remainder },
          },
        } = await getUsage();
        this.canGradeAdvanced = remainder !== 0;
      } catch (error) {
        this.showErrorToast(error.response?.data?.error);
      } finally {
        this.permissionsLoading = false;
      }
    },
    async fetchClasses() {
      try {
        this.classesLoading = true;

        const { data } = await getClasses({ limit: 1000 });

        this.classes = data.classes;
        const queryClassId = this.$route.query.classId;

        if (queryClassId) {
          this.selectedClass = this.classes.find(
            (c) => c.id === parseInt(queryClassId)
          );
        }

        this.classesLoading = false;
      } catch (error) {
        this.classesLoading = false;
        this.showErrorToast(error.response?.data?.error);
      }
    },

    inputDefaultHandler({ type, value }) {
      this[type] = value;
    },
  },

  computed: {
    isDirty() {
      return (
        this.name !== defaultFormState.name ||
        this.essayContents !== defaultFormState.essayContents ||
        this.files.length > 0 ||
        // this.selectedRubric !== this.defaultSelectedRubric ||
        this.rubricIntensity !== defaultFormState.rubricIntensity ||
        this.tda.sources !== defaultFormState.tda.sources ||
        this.tda.instructions !== defaultFormState.tda.instructions
      );
    },

    selectedRubricId() {
      return this.selectedRubric ? this.selectedRubric.id : null;
    },

    basicPlan() {
      return this.$store.getters.basicPlan;
    },

    litePlan() {
      return this.$store.getters.litePlan;
    },

    canNotUseAdvancedRubric() {
      return this.isAdvancedRubric && !this.canGradeAdvanced;
    },

    isAdvancedRubric() {
      if (!this.useCustomRubrics) {
        return false;
      }

      const isAdvancedRubric = (this.selectedRubric || {}).type === "Advanced";

      return isAdvancedRubric;
    },

    isFileUpload() {
      return this.uploadType === "files";
    },

    canSubmit() {
      const canSubmit =
        this.essayContents || this.essayFile || this.files.length > 0;

      // if file uploading, essay does not require a name
      if (this.isFileUpload) {
        return canSubmit;
      }

      return this.name && canSubmit;
    },

    useCustomRubrics() {
      return (
        this.selectedRubric &&
        this.selectedRubric.id !== this.selectedRubric.name
      );
    },

    canBulkUpload() {
      // TODO: Temporarily allow everyone to use bulk upload.
      // After 3.0 release, if we still allow all users to bulk upload, remove this code.
      return true;
      // return this.$store.getters.userIsNotPausedAndPlanMoreThenPro;
    },
  },

  mounted() {
    this.fetchClasses();
    this.getPermission();
  },
};
</script>

<style lang="scss" scoped>
.account-link {
  font-size: 12.8px;
  border: none;
  vertical-align: baseline;
}

.upload-content-area::v-deep {
  .uppy-Dashboard {
    height: 325px;
  }
}

.essay-submission-form::v-deep {
  .active .text-muted {
    color: white !important;
  }
}

.rubric-name {
  color: var(--Text-Body, #212529);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.rubric-created-by {
  color: var(--Text-Body, #212529);
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
}

.upload-area-container {
  position: relative;
}

.upload-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.7); /* Slightly transparent overlay */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10; /* Ensure it appears above other content */
}

.uploading-spinner {
  color: #532cb5;
  margin-right: 5px;
}

.uploading-text {
  color: #532cb5;
  font-weight: bold;
}
</style>
