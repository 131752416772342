import { app } from "@/main";

export default async function errorHandler(error) {
  const id = `error-toast-${Date.now()}`;
  const code = error.response?.data?.code;
  let message =
    error?.response?.data?.errors?.[0]?.message ||
    error?.response?.data?.error ||
    "There was a problem processing your request. Please try again or contact support.";
  let title = "Error";

  if (error.config?.url === "/free-tools/grade") {
    return Promise.reject(error);
  }

  if (code) {
    switch (code) {
      default:
        message = error.response?.data?.message || message;
        break;
    }
  }

  app.$bvToast.toast(message, {
    id,
    title,
    variant: "danger",
  });

  return Promise.reject(error);
}
