var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-modal',{staticClass:"export-to-platform-modal",attrs:{"id":`export-to-${_vm.platform}-modal`,"title":`Export feedback to ${_vm.LMS_PROVIDERS[_vm.platform].label}`,"size":"lg","centered":""},on:{"show":_vm.onShow,"hide":_vm.onHide},scopedSlots:_vm._u([{key:"modal-footer",fn:function({ close }){return [_c('div',{staticClass:"d-flex align-items-center justify-content-end"},[_c('b-button',{attrs:{"variant":"secondary"},on:{"click":close}},[_vm._v(_vm._s(_vm.closeText))]),[(!_vm.processed || (_vm.processed && _vm.resultStatus === 'warning'))?_c('b-button',{staticClass:"ml-2",attrs:{"variant":"info","disabled":_vm.showAll ? false : _vm.exporting || _vm.selectedIds.length === 0},on:{"click":function($event){!_vm.processed ? _vm.exportSelectedToPlatform() : _vm.retryAllFailedExports()}}},[_vm._v(" "+_vm._s(!_vm.processed ? "Export" : "Retry all failed exports")+" ")]):_vm._e(),(_vm.processed && _vm.resultStatus === 'error')?_c('b-button',{staticClass:"ml-2",attrs:{"variant":"info"},on:{"click":_vm.retryExport}},[_vm._v(" Retry export ")]):_vm._e(),(_vm.processed && _vm.resultStatus === 'auth')?_c('b-button',{staticClass:"ml-2",attrs:{"variant":"info","disabled":_vm.exporting ||
            _vm.resultData.some((essay) => essay.reAuthStatus === false)},on:{"click":() => _vm.exportSelectedToPlatform()}},[_vm._v(" Retry all failed exports ")]):_vm._e()]],2)]}}])},[(_vm.reAuthItem && _vm.$scopedSlots.reAuth)?_c('div',[_vm._t("reAuth",null,{"cancel":() => {
          _vm.reAuthItem = null;
        },"resolve":_vm.onReAuth})],2):_c('div',[(_vm.exporting)?_c('div',[_c('div',{staticClass:"d-flex flex-column align-items-center justify-content-center w-100",staticStyle:{"height":"300px"}},[_c('b-spinner',{staticClass:"my-5",staticStyle:{"width":"51px","height":"51px"},attrs:{"variant":"info"}}),_c('h4',{staticClass:"text-center"},[_vm._v(" Exporting to "+_vm._s(_vm.LMS_PROVIDERS[_vm.platform].label)+"... ")])],1)]):_vm._e(),(!_vm.exporting)?_c('div',[(!_vm.processed)?_c('div',{staticClass:"text mb-3"},[_vm._v(" Customize export to include: "),_c('export-options',{staticClass:"mt-2",attrs:{"available-options":_vm.availableOptions,"additional-options":_vm.additionalOptions},on:{"input":function($event){return _vm.$emit('update:selected-options', $event)}},model:{value:(_vm.innerSelectedOptions),callback:function ($$v) {_vm.innerSelectedOptions=$$v},expression:"innerSelectedOptions"}})],1):_c('div',{staticClass:"d-flex flex-column align-items-center justify-content-center w-100"},[_c('b-icon',{style:({ width: '48px', height: '48px' }),attrs:{"icon":_vm.result.icon,"variant":_vm.result.variant}}),_c('h4',{staticClass:"text-center mt-2"},[_vm._v(" "+_vm._s(_vm.result.label)+" ")]),(_vm.result.text)?_c('div',{staticClass:"text text-center mb-3"},[_vm._v(" "+_vm._s(_vm.result.text)+" ")]):_vm._e(),(_vm.resultStatus === 'auth')?_c('eg-selectable-table',{staticClass:"w-100",attrs:{"selectable":false,"items":_vm.resultData,"total":_vm.resultData.length,"limit":_vm.resultData.length,"fields":_vm.reAuthFields},scopedSlots:_vm._u([{key:"cell(reAuthStatus)",fn:function({ item }){return [_c('div',[_c('b-icon',{attrs:{"variant":item.reAuthStatus ? 'success' : 'danger',"icon":item.reAuthStatus
                    ? 'check-circle-fill'
                    : 'exclamation-circle-fill'}}),_vm._v(" "+_vm._s(item.reAuthStatus ? "Connected" : "Needs Re-authentication")+" ")],1)]}},{key:"cell(reAuthAction)",fn:function({ item }){return [(!item.reAuthStatus)?_c('div',{staticClass:"text-right"},[(_vm.platform === 'google')?_c('google-button',{attrs:{"type":"permissions","scope":_vm.permissionScope},on:{"done":function($event){return _vm.onReAuthGoogle(item, $event)}}},[_vm._v(" Re-authenticate ")]):_c('b-button',{attrs:{"variant":"info"},on:{"click":function($event){_vm.reAuthItem = item}}},[_vm._v(" Re-authenticate ")])],1):_vm._e()]}}],null,false,1165722552)}):_vm._e()],1),(_vm.show && !_vm.result.text)?_c('div',[_c('div',{staticClass:"w-100"},[(!_vm.processed)?_c('div',{staticClass:"mb-2"},[_vm._v(" The following essays will be exported to "+_vm._s(_vm.LMS_PROVIDERS[_vm.platform].label)+": ")]):_vm._e(),_c('eg-selectable-table',{staticClass:"w-100",attrs:{"selectable":false,"items":_vm.processed ? _vm.exportedEssays : _vm.showedEssays,"fields":_vm.fields,"total":_vm.processed ? _vm.exportedEssays.length : +_vm.count,"limit":_vm.processed ? _vm.exportedEssays.length : +_vm.limit,"current-page":_vm.currentPage},on:{"onChangePage":function($event){(_vm.currentPage = $event) && _vm.fetchEssays()}},scopedSlots:_vm._u([{key:"cell(createdAt)",fn:function({ value, item }){return [(item.exportable)?_c('div',[_vm._v(" "+_vm._s(_vm.formatDate(value))+" ")]):_c('div',{staticClass:"light-grey-text"},[_vm._v(" Not "),_c('img',{staticStyle:{"width":"12px","margin-bottom":"1px"},attrs:{"src":_vm.LMS_PROVIDERS[_vm.platform].icon,"alt":_vm.LMS_PROVIDERS[_vm.platform].label}}),_vm._v(" imported ")])]}},{key:"cell(exportedAt)",fn:function({ value, item }){return [(
                  item.exportable || (_vm.processed && _vm.resultStatus === 'success')
                )?_c('div',[_vm._v(" "+_vm._s(value ? _vm.formatDate(value) : "Never")+" ")]):_c('div',{staticClass:"light-grey-text"},[_c('b-icon',{attrs:{"variant":"danger","icon":"exclamation-circle-fill"}}),_vm._v(" Cannot be exported ")],1)]}}],null,false,526453624)})],1)]):_vm._e()]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }