<template>
  <div class="form-view">
    <div
      v-for="(form, formKey, index) in forms"
      :key="formKey"
      v-show="index === formsLength - 1"
    >
      <component
        :is="form.forceComponent"
        v-bind="form.props"
        v-on="form.listeners"
        @[form.callbackEvent]="form.callback"
        @close="$closeForm(formKey)"
      ></component>
    </div>
  </div>
</template>

<script>
import { forms } from "@/init/openForm";

export default {
  data() {
    return {
      forms,
    };
  },

  computed: {
    formsLength() {
      return Object.keys(this.forms).length;
    },
  },

  watch: {
    "$route.name"() {
      if (this.formsLength === 0) return;

      Object.keys(this.forms).forEach((formKey) => {
        this.$closeForm(formKey);
      });
    },
  },
};
</script>
