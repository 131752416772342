<template>
  <eg-form-group
    :label="noLabel ? '' : 'Assignment'"
    :description="description"
  >
    <b-skeleton v-if="loading" type="input" animation="wave" width="100%" />

    <template v-else>
      <eg-search-select
        v-if="withSearch"
        :items="assignmentsData"
        :value="selectedAssignment"
        titleKey="title"
        entity="assignment"
        allowDeselect
        @input="selectAssignment"
        @click:create="onClickCreate"
      >
        <template #item="{ item }">
          <slot
            v-if="$scopedSlots.item"
            name="item"
            v-bind:assignment="item"
          ></slot>
          <template v-else>
            {{ item.title }}
          </template>
        </template>
      </eg-search-select>

      <eg-dropdown
        v-else
        size="sm"
        :text="
          selectedAssignment ? selectedAssignment.title : 'Select assignment'
        "
      >
        <b-dropdown-item-button
          v-for="assignment in assignmentsData"
          :key="assignment.id"
          class="dropdown-item-wrapper"
          @click="() => selectAssignment(assignment)"
        >
          <slot
            v-if="$scopedSlots.item"
            name="item"
            v-bind:assignment="assignment"
          ></slot>
          <template v-else>
            {{ assignment.title }}
          </template>
        </b-dropdown-item-button>
      </eg-dropdown>
    </template>
  </eg-form-group>
</template>

<script>
import { getAssignments } from "@/api";
import EgFormGroup from "@/components/global/eg_form_group";
import EgDropdown from "@/components/global/eg_dropdown";
import EgSearchSelect from "@/components/global/eg_search_select.vue";

export default {
  components: {
    EgDropdown,
    EgSearchSelect,
    EgFormGroup,
  },

  props: {
    value: {
      type: Object,
    },

    withSearch: {
      type: Boolean,
      default: false,
    },

    noLabel: {
      type: Boolean,
      default: false,
    },

    description: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      loading: false,
      selectedAssignment: null,
      assignmentsData: [],
    };
  },

  methods: {
    onClickCreate() {
      this.$openForm("assignment", {
        props: { isRedirectAfterCreated: false },
        callback: async (value) => {
          await this.fetchAssignments();
          this.selectAssignment(value);
        },
      });
    },

    selectAssignment(assignmentData) {
      this.selectedAssignment = assignmentData;
      this.$emit("input", assignmentData);
    },

    async fetchAssignments() {
      this.loading = true;
      try {
        const { data } = await getAssignments({ limit: 1000 });
        this.assignmentsData = data.rows;
        this.$emit("input:assignments", this.assignmentsData);

        const queryAssignmentId = this.$route.query.assignmentId;

        if (queryAssignmentId) {
          const assignment = this.assignmentsData.find(
            (c) => c.id === parseInt(queryAssignmentId)
          );

          assignment && this.selectAssignment(assignment);
        }

        this.loading = false;
      } catch (error) {
        this.loading = false;
        this.$showToastError(error.response?.data?.error, {
          title: "Error fetching assignments",
        });
      }
    },
  },

  created() {
    this.selectedAssignment = this.value;
    this.fetchAssignments();
  },
};
</script>
