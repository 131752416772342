<template>
  <section>
    <b-row>
      <b-col>
        <h1 class="title">Community Update</h1>
      </b-col>
    </b-row>
    <b-row class="options-container">
      <b-col md="12">
        <b-card class="option-card banner">
          <div>
            <h2 class="option-title">EG 2.5 Release 🎉</h2>
            <div class="option-description">
              <div><b>Jan 15, 2025</b></div>
              <!-- <div>5:00 PM PT | 8:00 PM ET</div> -->
            </div>
            <b-button
              class="register-now"
              href="https://www.essaygrader.ai/blog/announcing-2-5-release-your-grading-superpower-now-even-more-connected"
              target="_blank"
              variant="Link"
            >
              Read the blog
            </b-button>
            <!-- <div class="text-additional">27 spots remaining</div> -->
          </div>
          <div class="divider"></div>
          <div>
            <h2 class="option-title">Here's what's new in this release!</h2>
            <div class="option-description">
              <ul>
                <li>
                  <b>Canvas LMS Integration:</b>
                  Effortlessly import classes, assignments, and student work
                  from your Canvas account, grade them in minutes and export
                  everything back seamlessly.
                </li>
                <li>
                  <b>Side-by-Side Grading Panel:</b> No more scrolling
                  endlessly! View student submissions alongside feedback, error
                  reports, and AI detection results—all on one screen.
                </li>
                <li>
                  <b>New Flags Feature:</b> Wondering if AI helped your students
                  a little too much? The new Flags highlight how much of an
                  essay is AI-written. (And stay tuned for more helpful flags
                  like plagiarism detection and passing grade thresholds coming
                  soon.)
                </li>
              </ul>
            </div>
            <h2 class="option-title">What's Next?</h2>
            <div class="option-description">
              <p>
                We're gearing up for our biggest release yet with groundbreaking
                AI grading features in just a few weeks—stay tuned!
              </p>
              <p>Simple. Powerful. Made for you. 😊</p>
            </div>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
export default {
  name: "CommunityUpdate",
};
</script>

<style scoped>
.select-import {
  position: relative;
}

.grading-options {
  position: relative;
  min-height: 700px;
}

.background-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}

.title {
  color: #000;
  font: 500 32px/119% Roboto, sans-serif;
  margin-left: 16px;
  position: relative;
}

.options-container {
  position: relative;
  margin: 25px 0 0;
}

.option-card {
  height: 100%;
}

.option-title {
  color: #000;
  font: 500 20px/120% Roboto, sans-serif;
  margin-bottom: 12px;
}

.option-icon {
  width: 32px;
  height: 32px;
}

.option-description {
  color: #000;
  font-family: Roboto, sans-serif;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 16px;
}

.register-now {
  color: hsla(257, 61%, 56%, 1);
  padding: 0;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

@media (max-width: 991px) {
  .grading-options {
    padding: 27px 20px;
  }
}

.banner {
  background-image: url("../../assets/banner-top.png");
  background-size: calc(100% + 2px) 8px;
  background-position: -1px -1px;
  background-repeat: no-repeat;

  .card-body {
    display: flex;

    & > div:first-child {
      flex-shrink: 0;
      width: 170px;
      color: hsla(210, 11%, 15%, 1);
    }

    .divider {
      width: 1px;
      height: 100%;
      margin: 0 16px;
      background-color: hsla(0, 0%, 87%, 1);
    }

    .option-description {
      font-size: 12px;
      font-weight: 400;
      line-height: 24px;
      text-align: left;

      ul {
        padding-left: 20px;
      }
    }

    .register-now {
      color: hsla(257, 61%, 56%, 1);
      padding: 0;
      font-family: Roboto;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
    }

    .text-additional {
      font-size: 12px;
      font-style: italic;
      line-height: 24px;
      color: hsla(210, 11%, 15%, 1);
    }
  }
}
</style>
