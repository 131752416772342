<script>
export default {
  name: "LoadingStep",
  data() {
    return {
      progress: 0,
      timer: null,
    };
  },
  mounted() {
    this.timer = setInterval(() => {
      this.progress++;
    }, 300);
  },
  beforeMount() {
    clearInterval(this.timer);
  },
};
</script>

<template>
  <div class="d-flex flex-column align-items-center my-5 flex-gap-1">
    <b-progress class="progressbar" :value="progress" :max="80" />
    <div>
      Grading has commenced - sit back, relax, and let EssayGrader handle the
      rest!
    </div>
    <img
      class="illustration mt-4"
      src="@/assets/illustrations/feedback-illustration.svg"
      alt="EssayGrader"
    />
  </div>
</template>

<style scoped lang="scss">
.illustration {
  width: 50%;
}
.progressbar {
  width: 75%;
  border-radius: 16px;
  ::v-deep {
    div[role="progressbar"] {
      background-color: #6a50f3;
    }
  }
}
</style>
