<template>
  <export-to-platform v-bind="$attrs" platform="google"></export-to-platform>
</template>

<script>
import ExportToPlatform from "./export_to_platform";
export default {
  name: "ExportToGoogleModal",

  components: {
    ExportToPlatform,
  },
};
</script>
