<script>
import { formatAuthorValue } from "@/utils/format";
import RubricLibraryView from "@/components/rubrics/rubric_library_view.vue";
import RubricForm from "@/components/rubrics/rubric_form.vue";
import SettingsSidebar from "@/components/rubrics/newRubric/settings_sidebar.vue";
import RubricLibrarySidebar from "@/components/rubrics/rubric_library.vue";
import RecentlyUsedRubrics from "@/components/rubrics/recently_used_rubrics.vue";

export default {
  name: "RubricFromTemplate",
  emits: ["createRubric", "close"],
  methods: {
    formatAuthorValue,
  },
  components: {
    RecentlyUsedRubrics,
    RubricLibrarySidebar,
    SettingsSidebar,
    RubricForm,
    RubricLibraryView,
  },
  data() {
    return {
      step: 0,
      selectedRubric: null,
    };
  },
  inject: ["updateRubricSettings", "clearRubricSettings"],
  beforeMount() {
    this.clearRubricSettings();
  },
};
</script>

<template>
  <b-row v-if="step === 0">
    <b-col cols="12">
      <h3>Rubric template library</h3>
    </b-col>
    <b-col cols="12" class="mb-3">
      <RecentlyUsedRubrics
        @selectRubric="selectedRubric = $event"
        :value="selectedRubric"
      />
    </b-col>
    <b-col cols="12">
      <RubricLibraryView
        @selectRubric="selectedRubric = $event"
        :value="selectedRubric"
        :showAddNew="false"
      />
    </b-col>
    <b-col cols="12" class="d-flex justify-content-end">
      <b-button variant="outline-info" @click="$emit('close')">
        Cancel
      </b-button>
      <b-button
        :disabled="!selectedRubric"
        class="ml-3 avoid-support-overlap"
        variant="info"
        @click="step = 1"
      >
        Continue
      </b-button>
    </b-col>
  </b-row>
  <b-row v-else-if="step === 1">
    <SettingsSidebar intensity grade subject language />
    <RubricLibrarySidebar
      :value="selectedRubric"
      @input="selectedRubric = $event"
      :autoCommit="false"
      hideCreateButton
    />
    <b-col cols="12"><h3>Details</h3></b-col>
    <b-col cols="12">
      <span>Rubric template <span class="red-text">*</span></span>
      <b-button
        v-b-toggle.rubric-library-sidebar
        variant="outline-info"
        size="sm"
        class="ml-3"
      >
        Change template
      </b-button>
      <b-button
        variant="outline-info"
        size="sm"
        class="ml-3"
        v-b-toggle.new-rubric-settings-sidebar
      >
        Edit rubric settings
      </b-button>
    </b-col>
    <b-col cols="12">
      <span>{{ selectedRubric.name }}</span>
    </b-col>
    <b-col cols="12" class="small">
      Created by
      <b>{{ formatAuthorValue(selectedRubric.author, $store) }}</b></b-col
    >
    <b-col cols="12">
      <h3>Rubric criteria</h3>
    </b-col>
    <b-col cols="12">
      <rubric-form
        :rubric="selectedRubric"
        :editing="false"
        @rubricSubmitted="$emit('createRubric', $event)"
        @close="$emit('close')"
      />
    </b-col>
  </b-row>
</template>

<style scoped lang="scss"></style>
