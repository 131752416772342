<script>
import SettingsSidebar from "@/components/rubrics/newRubric/settings_sidebar.vue";
import Settings from "@/components/rubrics/newRubric/settings.vue";
import RubricForm from "@/components/rubrics/rubric_form.vue";
import SettingsPreviewGrid from "@/components/rubrics/newRubric/settings_preview_grid.vue";

export default {
  name: "RubricFromScratch",
  components: { SettingsPreviewGrid, RubricForm, Settings, SettingsSidebar },
  emits: ["createRubric", "close"],
  inject: ["clearRubricSettings"],
  data() {
    return {
      step: 0,
      settings: {
        grade: true,
        intensity: true,
        language: true,
      },
    };
  },
  beforeMount() {
    this.clearRubricSettings();
  },
};
</script>

<template>
  <b-row>
    <SettingsSidebar v-bind="settings" />
    <b-col cols="12">
      <h3>Details</h3>
    </b-col>
    <template v-if="step === 0">
      <b-col cols="12">
        <Settings v-bind="settings" />
      </b-col>
      <b-col cols="12" class="d-flex justify-content-end">
        <b-button variant="outline-info" @click="$emit('close')">
          Cancel
        </b-button>
        <b-button
          class="ml-3 avoid-support-overlap"
          variant="info"
          @click="step = 1"
        >
          Continue
        </b-button>
      </b-col>
    </template>
    <template v-else-if="step === 1">
      <b-col cols="12" class="mb-3">
        <SettingsPreviewGrid v-bind="settings" />
      </b-col>
      <b-col cols="12">
        <rubric-form
          @rubricSubmitted="$emit('createRubric', $event)"
          @close="$emit('close')"
        />
      </b-col>
    </template>
  </b-row>
</template>

<style scoped lang="scss"></style>
