import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import initHotjar from "./init/hotjar";
import initSentry from "./init/sentry";
import initializeGrowthBook from "./init/growthbook";
import initShowToastError from "./init/toastError";
import initTrackEvent from "./init/trackEvent";
import initOpenForm from "./init/openForm";
import loginByCookieIfNeeded from "./init/loginByCookie";

import "./assets/main.scss";

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);

initHotjar(Vue);
initSentry(Vue);
initShowToastError(Vue);
initTrackEvent(Vue);
initOpenForm(Vue);
loginByCookieIfNeeded(store);

Vue.config.productionTip = false;

export let app = {};

initializeGrowthBook().then((gbInstance) => {
  app = new Vue({
    router,
    store,
    render: (h) => h(App),
    beforeCreate() {
      // Provide the GrowthBook instance
      this.$options.provide = {
        growthBook: gbInstance,
      };
    },
  }).$mount("#app");
});
