<template>
  <b-container class="imports-google-classroom-view">
    <b-row class="justify-content-center align-items-center">
      <b-col cols="12" md="9" lg="8" xl="7">
        <import-connect
          v-if="!serviceAccount.id || !serviceAccount.isGCAvailable"
          @input="updateServiceAccount"
        />
        <import-wizard
          v-if="
            serviceAccount && serviceAccount.id && serviceAccount.isGCAvailable
          "
          platform="google"
        />
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import ImportWizard from "@/components/imports/imports_wizard.vue";
import ImportConnect from "@/components/imports/imports_connect.vue";

export default {
  components: {
    ImportWizard,
    ImportConnect,
  },

  provide() {
    return {
      serviceAccount: this.serviceAccount,
      serviceName: "googleClassroom",
    };
  },

  data() {
    return {
      serviceAccount: {
        id: null,
        isGCAvailable: false,
      },
    };
  },

  methods: {
    updateServiceAccount(account) {
      if (!account.isGCAvailable) {
        return this.$showToastError(
          "Google Classroom permissions have expired or are not available for this account. Please try again and select all permissions."
        );
      }

      this.serviceAccount.id = account.gid;
      this.serviceAccount.isGCAvailable = account.isGCAvailable;
    },
  },
};
</script>
