<template>
  <div v-if="!flags || flagsLength === 0">{{ item.grade }}</div>
  <div v-else>
    <div
      :id="`grade-popover-${item.id}`"
      class="link"
      :class="colorClass"
      tabindex="0"
      @click.stop
    >
      <b-icon icon="flag" />
      {{ item.grade }}
    </div>
    <b-popover
      :show.sync="show"
      :target="`grade-popover-${item.id}`"
      placement="top"
      triggers="focus"
    >
      <template #title>
        <div v-if="flagsLength > 1" class="popover-title" :class="colorClass">
          {{ flagsLength }} flag{{ flagsLength > 1 ? "s" : "" }} detected
        </div>
        <div v-else class="popover-title" :class="colorClass">
          AI Use Detected
        </div>
        <b-button
          @click="show = false"
          aria-label="Close"
          variant="link"
          class="ml-auto text-dark"
          size="sm"
        >
          <b-icon icon="x" scale="1"></b-icon>
        </b-button>
      </template>
      <div class="flags">
        <div v-if="flags.includes('closeToGrade')" class="flag mb-1">
          Grade was <b>close to passing grade.</b>
        </div>
        <div v-if="flags.includes('usedAI')" class="flag mb-1">
          Detected <b>AI-use</b> in the essay.
        </div>
        <div v-if="flags.includes('plagiarism')" class="flag mb-1">
          Detected <b>plagiarism</b> in the essay.
        </div>
      </div>
      <b-button
        v-if="flags.includes('usedAI') || flags.includes('plagiarism')"
        class="mt-2 text-info px-0"
        variant="link"
        @click="onClickPopoverButton"
      >
        View details
      </b-button>
    </b-popover>
  </div>
</template>

<script>
import formatDate from "@/utils/formatDate";

export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
    isGrading: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      show: false,
    };
  },

  computed: {
    flags() {
      return (this.item.flags || []).map((flag) => flag.type);
    },

    flagsLength() {
      return this.flags.length;
    },

    colorClass() {
      switch (this.flagsLength) {
        case 0:
          return "";

        case 1:
          return "yellow";

        case 2:
          return "orange";

        default:
          return "danger";
      }
    },
  },

  methods: {
    formatDate,

    async onClickPopoverButton() {
      this.$router.push(`/essay/${this.item.id}?tab=2`);
    },
  },
};
</script>

<style lang="scss" scoped>
.link {
  display: inline;
  border-bottom: 1px solid transparent;

  &:hover {
    border-bottom: 1px solid currentColor;
  }
}
.yellow {
  color: var(--Text---Low, hsl(45, 74%, 36%));
}

.orange {
  color: hsla(30, 70%, 41%, 1);
}

.danger {
  color: hsla(354, 63%, 47%, 1);
}

.popover-title {
  padding: 0.5rem 0.75rem;
}

::v-deep {
  .popover-header {
    padding: 0;
    background-color: transparent;
    border-bottom: unset;
    display: flex;
  }
}
</style>
