import api from "@/api/api";

const generateImportApiByServiceName = (serviceName) => ({
  getClasses: (serviceId) => {
    return api.get(`/${serviceName}/${serviceId}/classes`);
  },

  getBulkAssignments: (serviceId, classesIds) => {
    return api.put(`/${serviceName}/${serviceId}/classes/assignments`, {
      classesIds,
    });
  },

  getAssignments: (serviceId, classesIds) => {
    return api.get(
      `/${serviceName}/${serviceId}/classes/${classesIds[0]}/assignments`
    );
  },

  getSubmissions: (serviceId, classId, assignmentId) => {
    return api.get(
      `/${serviceName}/${serviceId}/classes/${classId}/assignments/${assignmentId}/submissions`
    );
  },

  getDraftAssignment: (serviceId, classId, assignmentId) => {
    return api.get(
      `/${serviceName}/${serviceId}/classes/${classId}/assignments/${assignmentId}`
    );
  },

  importSubmissions: (serviceId, classId, assignmentId, payload) => {
    return api.put(
      `/${serviceName}/${serviceId}/classes/${classId}/assignments/${assignmentId}/submissions/import`,
      payload
    );
  },

  bulkImport: (serviceId, payload) => {
    return api.post(`/${serviceName}/${serviceId}/sync-tasks`, payload);
  },
});

export default {
  googleClassroom: generateImportApiByServiceName("google-classroom"),
  canvas: {
    ...generateImportApiByServiceName("canvas"),
    createCredentials: (canvasUrl, canvasToken) => {
      return api.put("/canvas/credentials/user-access-token", {
        url: canvasUrl,
        token: canvasToken,
      });
    },
  },
};
