<template>
  <div class="select-rubric">
    <eg-form-group
      label="Rubric"
      description="The same rubric must be used to grade all assignments selected in the previous step."
      required
    >
      <rubric-select-from-library
        :value="selectedRubric"
        @input="selectRubric"
      />
    </eg-form-group>
  </div>
</template>

<script>
import EgFormGroup from "@/components/global/eg_form_group.vue";
import RubricSelectFromLibrary from "@/components/rubrics/rubric_select_from_library.vue";

export default {
  components: {
    RubricSelectFromLibrary,
    EgFormGroup,
  },

  props: {
    value: {
      type: Object,
    },
  },

  data() {
    return {
      selectedRubric: null,
    };
  },

  watch: {
    value: {
      handler(newValue) {
        if (newValue && newValue.id) {
          this.selectedRubric = newValue;
        }
      },
      immediate: true,
    },
  },

  methods: {
    // used by wizard (outside) to validate data inside component
    validate() {
      return !!this.selectedRubric;
    },

    selectRubric(rubricData) {
      this.selectedRubric = rubricData;
      this.$emit("input", rubricData);
    },
  },
};
</script>

<style scoped>
.btn {
  color: #212529;
}

.btn:focus {
  box-shadow: none;
}

.btn-outline-primary {
  background-color: rgba(0, 123, 255, 0.1);
}

.empty-state::v-deep {
  h1 {
    font-size: 2rem;
  }
}
</style>
