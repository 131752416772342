import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";
import DashboardView from "../views/dashboard_view.vue";
import RegisterView from "../views/register_view.vue";
import LoginView from "../views/login_view.vue";
import ForgotPasswordView from "../views/forgot_password_view.vue";
import ResetPasswordV2View from "../views/reset_password_v2_view.vue";
import AccountView from "../views/account/account_view.vue";
import AccountIntegrationsView from "../views/account/integrations_view.vue";
import EssaysView from "../views/essays_view.vue";
import EssayView from "../views/essay_view.vue";
import SuccessView from "../views/success_view.vue";
import CanceledView from "../views/canceled_view.vue";
import SummarizeView from "../views/summarize_view.vue";
import AiDetectorView from "../views/ai_detector_view.vue";
import RubricsView from "../views/rubrics_view.vue";
import EditRubricView from "../views/edit_rubric_view.vue";
import NewRubricView from "../views/new_rubric_view.vue";
import PreviewRubricView from "../views/preview_rubric_view.vue";
import SupportView from "../views/support_view.vue";
import ClassesView from "../views/classes_view.vue";
import ClassView from "../views/class_view.vue";
import EditClassView from "../views/edit_class_view.vue";
import NewClassView from "../views/new_class_view.vue";

import AssignmentsView from "../views/assignments/assignments_view.vue";
import AssignmentView from "../views/assignments/assignment_view.vue";
import NewAssignmentView from "../views/assignments/new_assignment_view.vue";
import EditAssignmentView from "../views/assignments/edit_assignment_view.vue";

import UploadEssaysView from "../views/upload_essays_view.vue";
import ImportClassroomView from "../views/imports/classroom_view.vue";
import ImportCanvasView from "../views/imports/canvas_view.vue";

import FreeToolGrade from "../views/free-tools/grade.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "dashboard",
    component: DashboardView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/register",
    name: "register",
    component: RegisterView,
    meta: {
      hideNavbar: true,
    },
  },
  {
    path: "/login",
    name: "login",
    component: LoginView,
    meta: {
      hideNavbar: true,
    },
  },
  {
    path: "/forgot-password",
    name: "forgot-password",
    component: ForgotPasswordView,
    meta: {
      hideNavbar: true,
    },
  },
  {
    path: "/password-reset",
    name: "password-reset",
    component: ResetPasswordV2View,
    meta: {
      hideNavbar: true,
    },
  },
  {
    path: "/account",
    name: "account",
    component: AccountView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/account/integrations",
    name: "integrations",
    component: AccountIntegrationsView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/essays",
    name: "essays",
    component: EssaysView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/essay/:id",
    name: "essay",
    component: EssayView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/success",
    name: "success",
    component: SuccessView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/canceled",
    name: "canceled",
    component: CanceledView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/summarize",
    name: "summarize",
    component: SummarizeView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/ai-detector",
    name: "ai-detector",
    component: AiDetectorView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/rubrics",
    name: "rubrics",
    component: RubricsView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/rubric/new",
    name: "new-rubric",
    component: NewRubricView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/rubrics/:id",
    name: "preview-rubric",
    component: PreviewRubricView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/rubric/edit/:id",
    name: "edit-rubric",
    component: EditRubricView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/rubrics/shared/:slug",
    name: "shared-rubric",
    component: PreviewRubricView,
    meta: {
      isShareView: true,
    },
  },
  {
    path: "/support",
    name: "support",
    component: SupportView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/classes",
    name: "classes",
    component: ClassesView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/class/new",
    name: "new-class",
    component: NewClassView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/class/:id",
    name: "class",
    component: ClassView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/class/edit/:id",
    name: "edit-class",
    component: EditClassView,
    meta: {
      requiresAuth: true,
    },
  },

  // Assignments
  {
    path: "/assignments",
    name: "assignments",
    component: AssignmentsView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/assignment/new",
    name: "new-assignment",
    component: NewAssignmentView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/assignment/:id",
    name: "assignment",
    component: AssignmentView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/assignment/edit/:id",
    name: "edit-assignment",
    component: EditAssignmentView,
    meta: {
      requiresAuth: true,
    },
  },

  // Grading
  {
    path: "/upload-essays",
    name: "upload-essays",
    component: UploadEssaysView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/imports/google-classroom",
    name: "imports-google-classroom",
    component: ImportClassroomView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/imports/canvas",
    name: "imports-canvas",
    component: ImportCanvasView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/free-tools/grade",
    name: "free-tools-grade",
    component: FreeToolGrade,
    meta: {
      hideNavbar: true,
    },
  },
  {
    path: "*",
    redirect: "/login",
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

router.beforeEach((to, from, next) => {
  const isAuthenticated = store.state.token;

  if (!isAuthenticated) {
    if (to.meta.requiresAuth) {
      next("/login");
    } else {
      next();
    }
  } else {
    if (to.name === "login" || to.name === "register") {
      next("/");
    } else {
      next();
    }
  }
});

export default router;
